import React from 'react'
//material ui
import { TextField, InputAdornment } from '@material-ui/core'
import SortByAlpha from '@material-ui/icons/SortByAlpha'

function StringInput(props) {
  const { item, errors, register } = props
  return (
    <div>
      <TextField
        name={item.name}
        error={errors[item.name]} 
        helperText={errors[item.name] && errors[item.name].message} 
        label={item.name}
        fullWidth variant='outlined'
        color='primary' 
        placeholder={item.name}
        inputRef={register()}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SortByAlpha />
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

export default StringInput

