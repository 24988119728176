import React from 'react'
//router
import { Route, Redirect } from 'react-router-dom'
//redux
import { useSelector } from 'react-redux'
//loader
import PowerSplash from 'components/organisms/PowerSplash/PowerSplash.js'

export default function PrivateRoute ({component: Component, ...rest}) {
  const isVerifying = useSelector(store => store.auth.isVerifying)
  const authed = useSelector(store => store.auth.authenticated)
  const isDeleting = useSelector(store => store.project.isDeleting)
  //const isDeleting = useSelector(store => store.projects.isDeleting)

  return (
    <Route
      {...rest}
      render={(props) => (isVerifying || isDeleting) ? <PowerSplash /> : authed === true ? <Component {...props} /> : <Redirect to={{pathname: '/', state: {from: props.location}}} />}
    />
  )
}