import React from 'react'
//material ui
import { Grid, Typography, Card, Toolbar, Button, CardContent } from '@material-ui/core'
import FindInPage from '@material-ui/icons/FindInPage'
import BlurLinear from '@material-ui/icons/BlurLinear';
//redux
import { useSelector } from 'react-redux'
//components
import ModelAccuracy from '../../Train/Sections/ModelAccuracy'
import CardLoader from 'components/molecules/CardLoader/CardLoader'
import FeatureChart from '../../Train/Charts/FeatureChart'
import HeatMap from '../../Train/Charts/HeatMap'
//router
import { useRouteMatch, useHistory } from 'react-router-dom'

function Model(props) {
  const { classes } = props
  const fetching = useSelector(store => store.general.fetching)
  const mostRecentData = useSelector(store => store.project.mostRecentData)
  const checkpoints = useSelector(store => store.project.checkpoints)
  const match = useRouteMatch()
  const history = useHistory()

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant='h6' gutterBottom>Model</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={5} className={classes.dataCard} >
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1'>Accuracy</Typography>
              </Grid>
              <Grid item> 
                <Button onClick={e=>history.push(match.path.split('/').slice(0,-1).join('/') + '/train')} startIcon={<BlurLinear />} variant='text' color='inherit'>Train</Button>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            <CardLoader
              isLoading={fetching.checkpoints}
              placeHolder={checkpoints.length === 0}
              placeHolderLabel='No Training Found'
            >
              <ModelAccuracy chartOnly chartHeight={275} />
            </CardLoader>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={5} className={classes.dataCard}>
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1' gutterBottom>Importance</Typography>
              </Grid>
              <Grid item> 
                <Button onClick={e=>history.push(match.path.split('/').slice(0,-1).join('/') + '/train')} startIcon={<FindInPage />} variant='text' color='inherit'>View More</Button>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            <CardLoader
              isLoading={fetching.checkpoints}
              placeHolder={!mostRecentData}
              placeHolderLabel='No Training Found'
            >
            {mostRecentData?<FeatureChart data={mostRecentData.importance} chartHeight={275} />:null}
          </CardLoader>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={5} className={classes.dataCard}>
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1' gutterBottom>Conf. Matrix</Typography>
              </Grid>
              <Grid item> 
                <Button onClick={e=>history.push(match.path.split('/').slice(0,-1).join('/') + '/train')} startIcon={<FindInPage />} variant='text' color='inherit'>View More</Button>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            <CardLoader
              isLoading={fetching.checkpoints}
              placeHolder={!mostRecentData}
              placeHolderLabel='No Training Found'
            >
            <HeatMap chartHeight={275} mostRecent={mostRecentData} />
          </CardLoader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Model
