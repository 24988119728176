import { createMuiTheme } from '@material-ui/core/styles';

export let theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#21ce99',
      light: '#4dd7c8',
    },
    white: {
      main: '#fffff',
      disabled: '#b8bcc2'
    },
    secondary: {
      main: '#ce2155',
    },
    background: {
      //#18202c
      //default: '#040d14',
      default: '#131821',
      paper: '#19202e',
      card: '#19202e'
    },
    alternate: '#18202c'
  },
  appBar: {
    //backgroundColor: '#18202c'
  },
  typography: {
    //"fontFamily": `"Play", sans-serif`,
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    color: 'white',
    h1: {
      //"fontFamily": `"Orbitron", sans-serif`,
    },
    h2: {
      //"fontFamily": `"Orbitron", sans-serif`,
    },
    h3: {
      "fontFamily": `"Orbitron", sans-serif`,
    },
    h4: {
      //"fontFamily": `"Orbitron", sans-serif`,
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
      color: 'white',
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      //paper: {
      //  backgroundColor: '#232f3e',
      //},
    },
    MuiButton: {
      root: {
        //color: 'white'
      },
      label: {
        textTransform: 'none',
        //color: 'white'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
    MuiPaper: {
      root: {
        //border: 'none',
      },
    },
    MuiCard: {
      root: {
        //border: 'none',
        elevation: 0
      },
      
    }
  },
};