import React, { useState } from 'react'
//form
import { useForm } from "react-hook-form";
//@material ui
import { Grid, Typography, MenuItem, Slider, Button, makeStyles, OutlinedInput, Select, FormControl, Card, CardContent, CircularProgress } from "@material-ui/core";
import Loop from "@material-ui/icons/Loop";
import Schedule from '@material-ui/icons/Schedule'
import Done from '@material-ui/icons/Done'
//redux
import { useSelector, useDispatch } from 'react-redux'
//service
import { startTraining, handleTarget } from 'services/Project/project-api';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    //padding: '10px 10px 10px 0px',
    //fontWeight: 800
  },
  sectionText: {
    padding: '0px 10px 10px 0px',
    //height: '200px'
  },
  buttonWrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  card: {
    backgroundColor: theme.palette.background.default,
  },
  buttonProgress: {
    color: theme.palette.primary.main,
    position: 'absolute',
    top: '50%',
    //left: '50%',
    marginTop: -12,
    marginLeft: -60,
  },
  removeLeftMargin: {
    marginLeft: '-12px'
  }
}))

const statuses = [
  'Starting Request...',
  'Creating Deep Learning VM...',
  'Installing Dependencies...',
  'Downloading Data to VM...',
  'Preprocessing Data...',
  'Initializing Model...',
  'Training Model...',
  'Iteration Complete!',
  'Finishing Up...'
]

function TrainingForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const projectData = useSelector(store => store.project)
  const data_model = useSelector(store => store.dataModel.data_model)
  const [epoch, setEpoch] = useState(10)
  const targetId = data_model?data_model.findIndex(item => projectData.target_column === item.name):0
  const statusIndex = statuses.findIndex(item => projectData.trainStatus === item)
  const handleSliderChange = (event, newValue) => {
    setEpoch(newValue);
  };

  const { handleSubmit } = useForm();
  const onSubmit = data => {
    dispatch(startTraining(epoch))
  }
  return (
    <Card className={classes.card} elevation={0}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3} justify='flex-start' style={{margin: '0px'}}>
          <Grid item xs={12} sm={6}>
            <Typography className={classes.removeLeftMargin} variant="h6" color='primary'>
                Training Options
            </Typography>
              <div className={classes.sectionText}>
                <Typography color='textSecondary' variant='h6' gutterBottom>
                  <b>Epochs: </b>{epoch}
                </Typography>
                <Slider
                  defaultValue={30}
                  value={epoch}
                  aria-labelledby="discrete-slider"
                  valueLabelDisplay="auto"
                  onChange={handleSliderChange}
                  step={20}
                  marks
                  min={10}
                  max={500}
                  disabled={projectData.isTraining}
                />
                <div className={classes.buttonWrapper}>
                  <Button 
                    //style={{borderColor: "#21CE99", color: "white", minWidth: '125px', backgroundColor: '#21CE99'}} 
                    variant="contained"
                    disabled={targetId === -1 || projectData.isTraining}
                    size='large'
                    //onClick={e => handleClick(e)}
                    position='bottom'
                    color='primary'
                    type='submit'
                    startIcon={<Loop />}
                    >
                    Train
                  </Button>
                  {projectData.isTraining && <CircularProgress size={24} className={classes.buttonProgress} />}
                </div>
              </div>
          </Grid>
          {projectData.isTraining &&
          <Grid item xs={12} sm={6}>
            <Typography className={classes.removeLeftMargin} variant="h6" color='primary'>
              Training Status
            </Typography>
            <div style={{margin: 'auto', overflowX: 'hidden', whiteSpace: 'nowrap'}}>
            {statuses.map((item, index) => (
              projectData.trainStatus===item?(
                <div style={{display: 'flex'}}>
                  <div style={{padding: '3px 5px 0px 5px'}}><CircularProgress size={15} /></div>
                  <Typography color='primary' variant='body2'><b>{item.replace('...','')}</b></Typography>
                </div>
                ):index>statusIndex?(
                  <Typography variant='body2' ><Schedule color='secondary' style={{height: '15px'}} />{item.replace('...', '')}</Typography>
                ):<Typography variant='body2'><Done color='primary' style={{height: '15px'}} />{item.replace('...', '')}</Typography>
            ))}
            </div>
          </Grid>}
        </Grid>
      </form>
    </Card>
  )
}

export default TrainingForm
