import React from 'react'
import { useTheme, Grid, Typography, Select, MenuItem, FormControl, OutlinedInput } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import ExploreTable from 'components/organisms/ExploreTable/ExploreTable'
import TransferList from 'components/organisms/TransferList'
import { useDispatch, useSelector } from 'react-redux';
import { handleTarget } from 'services/Project/project-api';

export default function Preprocess() {
  const { setValue, register } = useForm();
  const dispatch = useDispatch()
  const data_model = useSelector(store => store.dataModel.data_model)
  const projectData = useSelector(store => store.project)
  const targetId = data_model?data_model.findIndex(item => projectData.target_column === item.name):0
  const theme = useTheme()

  React.useEffect(() => {
    register({name: "typeSelector"}); // custom register Antd input
    setValue('typeSelector', 0)
  }, [register, setValue])

  return (
    <React.Fragment>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h6" color='primary'>
            Target Column*
          </Typography>
          <Typography variant="caption">
            Select a column to be the target (what you want your model to predict).
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormControl
              name="related"
              validations="isExisty"
              validationError="Must choose a column"
              variant='outlined'
              //fullWidth
              fontColor='white'
          >
            <Select 
              onChange={e => {e.preventDefault(); dispatch(handleTarget(data_model[e.target.value].name))}} 
              value={targetId} 
              style={{ minWidth: '200px', backgroundColor: theme.palette.background.card }} 
              input={<OutlinedInput />}
              disabled={projectData.checkpoint > 0}
            >
              {data_model?data_model.map((column, i) => (
                column.type==='categorical'?<MenuItem value={i}>{column.name}</MenuItem>:false
              )):null}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color='primary'>
            Filter Unuseful Columns*
          </Typography>
          <Typography variant='caption'>
            Please exclude any data that has no correlation to your target column. (e.g. Row Number, Customer Id, etc)
          </Typography>
        </Grid>
        <Grid item >
          <TransferList checkpoint={projectData.checkpoint} left={data_model.filter(item => !projectData.ignored_columns.includes(item.name)).map(item => item.name)} right={projectData.ignored_columns} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" color='primary'>
            Column Summary
          </Typography>
          <Typography variant='caption' gutterBottom>
            Click row for detail
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <ExploreTable />
        </Grid>
      </Grid>
    </React.Fragment>
  )
}