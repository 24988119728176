import { UserActions } from 'redux/User/actions';

const initialState = {
  projects: [],
  apiKeys: []
};

export function userReducer(state = initialState, action) {
  switch (action.type) {

    case UserActions.SET_PROJECTS:
      return {
        ...state,
        projects: action.data
      };

    case UserActions.SET_API_KEYS:
      return {
        ...state,
        apiKeys: action.data
      };

    default:
      return state;
  }
}
