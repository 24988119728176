import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider'
import CardHeader from '@material-ui/core/CardHeader'
import { useDispatch } from 'react-redux';
import { handleIgnoredColumns } from 'services/Project/project-api'

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 'auto',
    marginBottom: 'auto'
  },
  card: {
    width: 200,
    height: 230,
    overflow: 'auto',
    //backgroundColor: theme.palette.background.default,
    //border: '1px solid rgba(81, 81, 81, 1)',
  },
  cardHeader: {
    padding: theme.spacing(1, 2),
    fontSize: '12px'
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}));

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

export default function TransferList(props) {
  const { left, right, checkpoint } = props
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const dispatch = useDispatch()
  //const [left, setLeft] = React.useState([0, 1, 2, 3]);
  //const [right, setRight] = React.useState([4, 5, 6, 7, 8, 9, 10]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  console.log(checkpoint, 'CHECK', checkpoint > 0)
  const handleCheckedRight = () => {
    dispatch(handleIgnoredColumns(right.concat(leftChecked)))
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    dispatch(handleIgnoredColumns(not(right, rightChecked)))

    setChecked(not(checked, rightChecked));
  };

  const customList = (title, items) => (
    <Card className={classes.card}>
      <CardHeader
        className={classes.cardHeader}
        //avatar={'test'}
        title={title}
        fontSize={12}
        titleTypographyProps={{variant: 'body1'}}
      />
      <Divider />
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-item-${value}-label`;

          return (
            <ListItem key={value} role="listitem" button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  unselectable={true}
                  size='small'
                  disabled={checkpoint > 0}
                  style={{paddingTop: 0, marginTop: 0, paddingBottom: 0, marginBottom: 0}}
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  return (
    <Grid container spacing={2} justify="flex-start" alignItems="center" className={classes.root}>
      <Grid item>{customList('Include', left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || checkpoint > 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || checkpoint > 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList('Exclude', right)}</Grid>
    </Grid>
  );
}