import React, { useEffect } from 'react'
import { Grid, Typography, Select, MenuItem, FormControl, OutlinedInput, Divider } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux';
import TrainingForm from './components/TrainingForm';
import ModelAccuracy from './components/ModelAccuracy'
import { setError } from 'redux/General/actions'

export default function Train() {
  const { setValue, register } = useForm();
  const dispatch = useDispatch()
  const data_model = useSelector(store => store.dataModel.data_model)
  const projectData = useSelector(store => store.project)
  const targetId = data_model?data_model.findIndex(item => projectData.target_column === item.name):0
  const trainingError = useSelector(store => store.project.trainingError)

  useEffect(() => {
    if (trainingError) {
      dispatch(setError('TRAINING', 'Last Training Job Failed!'))
    }
  }, [dispatch, trainingError])


  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <TrainingForm />
        </Grid>
        <Grid item xs={12}>
          <ModelAccuracy />
        </Grid>
      </Grid>
      <br />
      <Divider />
    </React.Fragment>
  )
}