import React from 'react'
//material ui
import { Typography, Grid, CircularProgress, Card, CardContent } from '@material-ui/core'
import Done from "@material-ui/icons/Done";
import Schedule from "@material-ui/icons/Schedule";
//redux
import { useSelector } from 'react-redux'
//blink
import Blink from 'react-blink-text'
//components
import DonutChart from '../Charts/DonutChart'
import EpochChart from '../Charts/EpochChart'

const statuses = [
  'Starting Request...',
  'Creating Deep Learning VM...',
  'Installing Dependencies...',
  'Downloading Data to VM...',
  'Preprocessing Data...',
  'Initializing Model...',
  'Training Model...',
  'Iteration Complete!',
  'Finishing Up...'
]

function TrainingStats() {
  const projectData = useSelector(store => store.project)
  const { mostRecentData } = projectData
  const statusIndex = statuses.findIndex(item => projectData.trainStatus === item)
  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant='h5' style={{fontWeight: 800, color: 'white', paddingLeft: '15px'}}>
          <Blink text='Training In Progress...' fontSize={25} color="#21CE99" />
        </Typography>
        
        <Grid container justify='space-evenly' style={{color: 'white', padding: '16px'}} spacing={3}>
          <Grid item xs={12} sm={4}>
            <div style={{margin: 'auto', overflowX: 'hidden', whiteSpace: 'nowrap'}}>
            {statuses.map((item, index) => (
              projectData.trainStatus===item?(
                <div style={{display: 'flex'}}>
                  <div style={{padding: '3px 5px 0px 5px'}}><CircularProgress size={15} /></div>
                  <Typography color='primary' variant='body2'><b>{item.replace('...','')}</b></Typography>
                </div>
                ):index>statusIndex?(
                  <Typography variant='body2' ><Schedule color='secondary' style={{height: '15px'}} />{item.replace('...', '')}</Typography>
                ):<Typography variant='body2'><Done color='primary' style={{height: '15px'}} />{item.replace('...', '')}</Typography>
            ))}
            </div>
          </Grid>
          <Grid item xs={4} sm={4} style={{margin: 'auto'}}>
            <Typography variant='subtitle1' style={{textAlign: 'center'}}>Epochs</Typography>
            <EpochChart />
          </Grid>

          <Grid item xs={4} sm={4} style={{margin: 'auto'}}>
            <Typography variant='subtitle1' style={{textAlign: 'center'}}>Accuracy</Typography>
            <DonutChart value={mostRecentData?mostRecentData.accuracy:0} title='Epochs' />
          </Grid>

        </Grid>
      </CardContent>
    </Card>
  )
}

export default TrainingStats
