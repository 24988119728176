import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Logo from 'assets/img/fullLogo.png'
import { useHistory } from 'react-router-dom';
import HeaderLinks from './HeaderLinks';
import Section from 'components/organisms/Section';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%'
  },
  menuButton: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    color: 'white',
    fontWeight: 100,
    cursor: 'pointer',
    fontSize: 20
  },
  logo: {
    paddingTop: '7px',
    paddingRight: '7px',
    cursor: 'pointer'
  },
  bar: {
    border: '0px'
  },
  tool: {
    padding: '0px'
  },
  sectionNoPadding: {
    paddingTop: 0,
    paddingBottom: 0
  }
}));

export default function Header(props) {
  const classes = useStyles();
  const [headerColor, setHeaderColor] = useState('transparent')
  const history = useHistory()

  useEffect(() => {
    if (props.headerScroll) {
      window.addEventListener("scroll", headerColorChange);
    }
  });

  function headerColorChange() {
    const { headerScroll } = props;
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > headerScroll.height) {
      setHeaderColor(headerScroll.color)
    } else {
      setHeaderColor('transparent')
    }
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} position="fixed" color={headerColor} elevation={0}>
        <Section className={classes.sectionNoPadding}>
        <Toolbar className={classes.tool}>
          <div data-aos={'fade-right'} className={classes.logo} onClick={e => history.push('/')}>
            <img src={Logo} alt='logo' height={30} />
          </div>
          <div className={classes.menuButton}>
            <HeaderLinks />
          </div>
        </Toolbar>
        </Section>
      </AppBar>
    </div>
  );
}