import React from 'react'
//material ui
import { TextField, InputAdornment } from '@material-ui/core'
import Filter7 from '@material-ui/icons/Filter7'

function NumberInput(props) {
  const { item, errors, register } = props
  return (
    <div>
      <TextField
        name={item.name}
        error={errors[item.name]} 
        helperText={errors[item.name] && errors[item.name].message} 
        label={item.name}
        fullWidth
        variant='outlined'
        color='primary' 
        placeholder={item.name}
        inputRef={register({
          pattern: item.type==='numeric'?{
            value: /^(0|[1-9][0-9]*)$/,
            message: 'Must be numeric'
          }:{}
        })} 
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Filter7 />
            </InputAdornment>
          ),
        }}
      />
    </div>
  )
}

export default NumberInput
