import React from 'react'
//material ui
import { Dialog, DialogTitle, Typography, makeStyles } from '@material-ui/core'
import Warning from '@material-ui/icons/Warning'
//redux
import { useSelector } from 'react-redux'
//components
import TrainingError from './ModalTypes/TrainingError'
import GeneralError from './ModalTypes/GeneralError'


const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  warning: {
    paddingRight: '10px',
    paddingTop: '0px'
  },
  container: {
    padding: '15px',
    //width: '325px'
  }
}))

const MODAL_COMPONENTS = {
  'TRAINING': <TrainingError />,
  'GENERAL': <GeneralError />
}

export default function ErrorDialog (props) {
  const classes = useStyles()
  const { error, errorType } = useSelector(store=>store.general)
  
  return (
    <Dialog open={error}>
       <div className={classes.container}>
        <DialogTitle id="simple-dialog-title">
          <div className={classes.flex}>
            <div className={classes.warning}>
                <Warning fontSize='large' color='secondary' />
            </div>
            <Typography color='secondary' variant='h5'>Uh Oh!</Typography>
          </div>
        </DialogTitle>
        {errorType && MODAL_COMPONENTS[errorType]}
      </div>
    </Dialog>
  )
}