import React, {useEffect, useState} from 'react';
import AnyChart from 'anychart-react'
import anychart from 'anychart'
import { useTheme } from '@material-ui/core';

export default function ValueDist(props) {
  const { mostRecent, chartHeight } = props
  const [confusion, setConfusion] = useState([])
  const theme = useTheme()

  useEffect(() => {
    if (mostRecent && mostRecent.confusion) {
      setConfusion(mostRecent.confusion)
    }
  }, [mostRecent])

  const anyconfig = {
    width: '100%',
    height: chartHeight?chartHeight:'330px',
    margin: 0,
    padding: 0,
    type: 'heatMap',
    data: confusion,
    normal: {stroke: `3 ${theme.palette.background.card}`},
    selected: {fill: theme.palette.primary.light},
    background: {fill: theme.palette.background.card},
    xAxis: {stroke: theme.palette.background.card, labels: {fontColor: 'white'}, title: {fontColor: 'white', text: 'Predicted Label'}},
    yAxis: {stroke: theme.palette.background.card, labels: {fontColor: 'white'}, title: {fontColor: 'white', text: 'Actual Label'}},
    colorScale: anychart.scales.linearColor('fff', theme.palette.primary.main) //.ranges([{less: .33, color: '#e57373'}, {from: .33, to: .70, color: "#ffb74d"}, {greater: .70, color: "#21CE99"}]),
  }
  
  return (
    <AnyChart
      {...anyconfig}
    />
  );
}