
export const DataModelActions = {
  SET_DATA_MODEL: '@project/setDataModel',
};

export function setDataModel(data) {
  return {
    type: DataModelActions.SET_DATA_MODEL,
    data: data,
  };
}

