import React from 'react'
//material ui
import { makeStyles, Grid, useMediaQuery, useTheme } from '@material-ui/core'
import ListAlt from '@material-ui/icons/ListAlt'
import CloudUpload from '@material-ui/icons/CloudUpload';
import Loop from '@material-ui/icons/Loop'
import Search from '@material-ui/icons/Search'
import Public from '@material-ui/icons/Public'
import AttachMoney from '@material-ui/icons/AttachMoney'
//comonenets
import SectionHeader from 'components/molecules/SectionHeader'
import DescriptionListIcon from 'components/organisms/DescriptionListIcon'

const useStyles = makeStyles(theme => ({
  root: {},
  iconCover: {
    width: 60,
    height: 60,
    background: 'url(/bgicon.svg) no-repeat center center',
    backgroundSize: 'contain',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    fontSize: 35,
    [theme.breakpoints.up('md')]: {
      width: 80,
      height: 80,
      fontSize: 40,
    },
  },
  cta: {
    marginTop: theme.spacing(3),
  },
}))

export const data = [
  {
    title: '1. Upload',
    subtitle:
      'We support bulk uploading CSVs, and have integrations with most data storage products coming soon',
    icon: <CloudUpload color='primary' style={{fontSize: 60}} />
    },
  {
    title: '2. Prepare',
    subtitle: "Simply select the goals of the project from your data and we'll handle the rest",
    icon: <ListAlt color='primary' style={{fontSize: 60}} />
    },
  {
    title: '3. Train',
    subtitle: "Apply the latest machine learning training methods to your data with just a click of a button",
    icon: <Loop color='primary' style={{fontSize: 60}} />
  },
  {
    title: '4. Understand',
    subtitle: 'Easily test, evaluate, and derive insights from your trained model',
    icon: <Search color='primary' style={{fontSize: 60}} />
  },
  {
    title: '5. Deploy',
    subtitle: 'One-click deployment allows you to make your model available via API to other applications or users',
    icon: <Public color='primary' style={{fontSize: 60}} />
  },
  {
    title: '6. Realize',
    subtitle: 'Gain impactful business value from your trained and tested model',
    icon: <AttachMoney color='primary' style={{fontSize: 60}} />

  }
];

function HowItWorks(props) {
  const classes = useStyles()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div id='about' className={classes.section} data-aos={'fade-up'}>
      <SectionHeader label="Fully Automated" title="How it works" />
      <Grid container spacing={isMd ? 4 : 2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={4} key={index} data-aos="fade-up">
            <DescriptionListIcon
              icon={
                item.icon
              }
              title={item.title}
              subtitle={item.subtitle}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

export default HowItWorks
