import React from 'react'
//redux
import { useSelector } from 'react-redux'
//material
import { Typography, makeStyles, Paper } from '@material-ui/core'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex'
  },
  userCard: {
    height: '75px',
    width: '75px',
    backgroundColor: theme.palette.primary.main
  },
  user: {
    height: '100%',
    width: '100%',
    color: theme.palette.background.default
  },
  title: {
    flex: '1 1 100%',
  },
  userInfoText: {
    padding: '0px 0px 15px 25px'
  },
}))

function TopSection(props) {
  const classes = useStyles()
  const {authUser, email} = useSelector(store => store.auth)
  return (
    <div className={classes.root}>
      <Paper className={classes.userCard}>
        <AccountCircleIcon className={classes.user} />
      </Paper>
      <div className={classes.userInfoText}>
        <Typography variant='h5' className={classes.title}>{authUser}</Typography>
        <Typography style={{paddingTop: '7px'}} className={classes.title}>{email}</Typography>
      </div>

    </div>
  )
}

export default TopSection
