import React from 'react'
import { withStyles, Tab } from '@material-ui/core'

const PowerTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    //color: 'white',
    fontSize: 17,
    minWidth: 72,
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      color: theme.palette.primary.main,
      opacity: 1,
    },
    '&$selected': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&:focus': {
      color: theme.palette.primary.main,
    },
  },
  selected: {},
}))((props) => <Tab disableRipple {...props} />);

export default PowerTab