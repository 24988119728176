import React from 'react';
import logo from 'assets/img/iconLogo.png'
import { Grid } from '@material-ui/core';
import { CircularProgress, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  middleItem: {
    textAlign: 'center'
  },
  loader: {
    marginTop: '-65px',
  },
  container: {
    height: '100vh'
  }
}))

function PowerSplash() {
  const classes = useStyles()
	return (
    <Grid container className={classes.container} justify='center' alignItems='center' direction='row'>
      <Grid item className={classes.middleItem}>
        <img width="50" src={logo} alt="logo" />
        <div className={classes.loader}>
          <CircularProgress size={70} disableShrink color='primary' />
        </div>
        
      </Grid>
    </Grid>
	);
}

export default React.memo(PowerSplash);
