import React, {useEffect, useMemo, useState} from 'react';
import AnyChart from 'anychart-react'
import { useTheme } from '@material-ui/core';

export default function ValueDist(props) {
  const {words} = props
  const theme = useTheme()
  const [dataSet, setDataSet] = useState([])

  useEffect(() => {
    const words2 = Object.keys(words).slice(0,500).map(key => {
      return {x: key, y: words[key]}
    })
    setDataSet(words2)
  }, [words])

  var chart = useMemo(() => {
    const anyconfig = {
      //width: '100%',
      height: 550,
      type: 'tagCloud',
      data: dataSet,
      //title: 'Word Cloud',
      angles: [0, -45, 90],
      textSpacing: 1,
      background: {fill: theme.palette.background.card}
    }
    if (dataSet.length > 0) {
      return (<AnyChart {...anyconfig} />)
    } else {
      return 'Loading...'
    }
  }, [dataSet, theme.palette.background.card])
  
  return (
    <div>
      {chart}
    </div>
  );
}