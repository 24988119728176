import { DataModelActions } from 'redux/DataModel/actions';

const initialState = {
  data_model: [],
};

export function dataModelReducer(state = initialState, action) {
  switch (action.type) {

    case DataModelActions.SET_DATA_MODEL:
      return {
        ...state,
        data_model: action.data
      };

    default:
      return state;
  }
}
