import React, { useEffect, useState } from 'react'
//page components
import TopSection from './components/TopSection'
import Projects from './components/Projects'
import ApiKeys from './components/ApiKeys'
import Account from './components/Account'
//core components
import PowerTab from 'components/molecules/Tabs/PowerTab'
import PowerTabs from 'components/molecules/Tabs/PowerTabs'
//material
import { Grid, makeStyles } from '@material-ui/core'
import Header from 'components/organisms/Header/Header'
//redux
import { useDispatch } from 'react-redux'
import { fetchProjects, fetchAPIKeys } from 'services/User/user-api'
import Section from 'components/organisms/Section'

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: '20px'
  },
  tabs: {
    marginLeft: '-16px'
  }
}))

function UserDashboard(props) {
  const classes = useStyles()
  const [value, setValue] = useState(0)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchProjects())
    dispatch(fetchAPIKeys())
  }, [dispatch])

  return (
    <div id='userdashboard'>
    <Header headerScroll={{height: 10, color: 'primary'}} />
    <Section>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <TopSection />
        </Grid>

        <Grid item xs={12}>
          <PowerTabs className={classes.tabs} value={value} textColor='primary' indicatorColor="primary" onChange={(e, newValue) => setValue(newValue)}>
            <PowerTab label='Projects' />
            
            <PowerTab label='Account' />
          </PowerTabs>
        </Grid>

        {value===0?
          <Grid item xs={12}>
            <Projects />
          </Grid>:null
        }

        {value===2?(
          <Grid item xs={12}>
            <ApiKeys />
          </Grid>):null
        }

        {value===1?
          <Grid item xs={12}>
            <Account />
          </Grid>:null
        }
      </Grid>
    </Section>
      




    </div>
  )
}

export default UserDashboard
