import React from 'react'
//redux
import { useDispatch, useSelector } from 'react-redux'
//material
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Done from '@material-ui/icons/Done'
import Close from '@material-ui/icons/Close'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { Button, useTheme } from '@material-ui/core'
import { CircularProgress } from '@material-ui/core';
//redux
import { uploadModal } from 'redux/Project/actions'

export default function UploadDialog (props) {
  const dispatch= useDispatch()
  const theme = useTheme()
  const isUploadingFiles = useSelector(store => store.project.isUploadingFiles)
  const uploadModalOpen = useSelector(store => store.project.uploadModal)
  const uploadFiles = useSelector(store => store.project.uploadFiles)

  return(
    <Dialog
      open={uploadModalOpen}
      PaperProps={{
        style: {
          backgroundColor: theme.palette.background.default,
          color: 'white',
          boxShadow: 'none',
        },
      }}
    >
      <div>
        <DialogTitle id="simple-dialog-title">
          <div style={{display: 'flex'}}>
            <div style={{paddingRight: '10px'}}>
                <CloudUpload style={{color: 'white', height: '30px'}} />
            </div>
          Uploading Files...
          </div>
        </DialogTitle>
            
        <List>
          {uploadFiles.map((file) => (
          <ListItem button key={file.name}>
              <div style={{padding: '5px 10px 5px 5px'}}>
                  {file.success==='pending'?<CircularProgress size={25} />:file.success?<Done style={{color: '#21CE99', height: '15px'}} />:<Close style={{color: '#FF7597', height: '15px'}} />}
              </div>
              <ListItemText primary={file.name} />
          </ListItem>
          ))}

        </List>
        {!isUploadingFiles?(
          <DialogActions>
              <div style={{width: '100%', textAlign: 'center'}}>
                <Button variant='contained' color='primary' onClick={e => dispatch(uploadModal(false))}>Okay</Button>
              </div>
          </DialogActions>
        ):null}
      </div>
    </Dialog>
  )
}