import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
} from '@material-ui/core';
import Image from 'components/atoms/Image'
import SectionHeader from 'components/molecules/SectionHeader';
import Section from 'components/organisms/Section';
import CardPricingStandard from 'components/organisms/UseCasesStandard'
import { CheckBox } from '@material-ui/icons';

export const pricings = [
  {
    title: 'Banking',
    subtitle: 'Compete aggressively by leveraging data from your current clients to nurture existing relationships and find new clients.',
    monthly: 49,
    annual: 480,
    priceSuffix: ' / MO',
    features: [
      "Predict your client's next product move.",
      'Build better credit models.',
      'Forecast losses more accurately',
      'Optimize pricing',
      'Determine which clients have a high probability of leaving.',
    ],
    //disclaimer: 'Fully featured 30-day free trial',
    //isHighlighted: false,
  },
  {
    title: 'Sales & Retail',
    subtitle: "Understand and adapt to your potential and current customer's ever-changing needs.",
    monthly: 79,
    annual: 780,
    priceSuffix: ' / MO',
    features: [
      'Predict next customer CRM state',
      'Predict sentiment using data from surveys and reviews',
      'Predict conversion of a sales lead',
      'Identify the best promotion strategy for your products',
      'Optimize pricing for clients or products'
    ],
    //disclaimer: 'Fully featured 30-day free trial',
    //isHighlighted: true,
  },
  {
    title: 'Healthcare',
    subtitle: 'Help improve your outcomes, streamline operations, and identify financial risks.',
    monthly: 69,
    annual: 680,
    priceSuffix: ' / MO',
    features: [
      'Determine which members are at risk of leaving your health plan',
      'Flag potential fraudulent claims',
      'Predict inpatient stay length and readmission risk',
      'Forecast staffing needs',
    ],
    //disclaimer: 'Fully featured 30-day free trial',
    //isHighlighted: false,
  },
];

const useStyles = makeStyles(theme => ({
  root: {},
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
    },
  },
  sectionContainer: {
    backgroundColor: theme.palette.primary.main,
  },
  textWhite: {
    color: 'white',
  },
  fontWeightBold: {
    fontWeight: 'bold',
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(0, 2),
  },
  toggleButtonGroup: {
    background: 'transparent',
  },
  toggleButton: {
    background: 'transparent',
    border: '1px solid white',
    padding: theme.spacing(1, 5),
  },
  toggleButtonActive: {
    backgroundColor: 'white !important',
  },
  toggleTitle: {
    textTransform: 'capitalize',
  },
  toggleTitleActive: {
    color: theme.palette.primary.main,
  },
  pricingContainer: {
    position: 'relative',
  },
  shapeContainer: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    width: '100%',
    background: theme.palette.primary.main,
    height: 300,
  },
  shapeImage: {
    objectFit: 'cover',
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  cardPricing: {
    '& .countup-number__count-wrapper': {
      textAlign: 'left',
      marginBottom: 0,
      fontWeight: 'bold',
    },
  },
  sectionSmallPaddingBottom: {
    paddingBottom: 20,
  },
  textDark: {
    color: theme.palette.background.default
  }
}));

const Main = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const data = pricings
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <div className={classes.sectionContainer}>
        <Section narrow className={clsx(classes.pagePaddingTop, classes.sectionSmallPaddingBottom)}>
          <SectionHeader
            title="Use Cases"
            subtitle="We are applicable across so many different industries and problems... Here's a few examples."
            titleProps={{
              className: clsx(classes.fontWeightBold, classes.textDark),
              variant: 'h2',
            }}
            subtitleProps={{
              className: classes.textDark,
            }}
            data-aos="fade-up"
          />
        </Section>
      </div>
      <div className={classes.pricingContainer}>
        <div className={classes.shapeContainer}>
          <Image
            src="/triangle-shape.svg"
            className={classes.shapeImage}
            lazy={false}
          />
        </div>
        <div className={classes.pricingWrapper}>
          <Section className={classes.sectionNoPaddingTop}>
            <Grid container spacing={isMd ? 4 : 2}>
              {data.map((item, index) => (
                <Grid item xs={12} md={4} data-aos="fade-up" key={index}>
                  <CardPricingStandard
                    variant="outlined"
                    withShadow={item.isHighlighted ? true : false}
                    title={item.title}
                    liftUp
                    subtitle={item.subtitle}
                    features={item.features}
                    featureCheckComponent={
                      <CheckBox color='primary' />
                    }
                    disclaimer={item.disclaimer}
                    className={classes.cardPricing}
                  />
                </Grid>
              ))}
            </Grid>
          </Section>
        </div>
      </div>
    </div>
  );
};

Main.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default Main;
