import React, { useEffect } from 'react';
//theme
import { theme } from 'global/theme.js'
import { ThemeProvider } from '@material-ui/core/styles';
//components
import TopScroll from 'containers/TopScroll/TopScroll'
import AuthModal from 'containers/AuthModal/AuthModal'
import ErrorDialog from 'components/organisms/ErrorDialog';
import DeleteDialog from 'components/organisms/DeleteDialog/DeleteDialog'
import ResetDialog from 'components/organisms/ResetDialog/ResetDialog'
//routes
import SwitchRoutes from 'containers/SwitchRoutes/SwitchRoutes';
import { Router } from "react-router-dom";
import history from 'global/history'
import { CssBaseline } from '@material-ui/core';
//aos
import AOS from 'aos'
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      once: true,
      delay: 50,
      duration: 500,
      easing: 'ease-in-out',
    });
  })
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router history={history}>
        <TopScroll>
          <SwitchRoutes />
        </TopScroll>
      </Router>
      <AuthModal />
      <ErrorDialog />
      <DeleteDialog />
      <ResetDialog />
    </ThemeProvider>
  );
}

export default App;
