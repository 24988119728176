//firebase
import { db } from 'global/firebase'

export function addMessage (data) {
  return dispatch => {
    db.collection("support")
    .add({
      email: data.email,
      fullname: data.fullname,
      message: data.message,
      time: Date.now()
    })
    .then(response => {
      window.alert("Thanks for your message! We'll get back to you ASAP.")
    })
    .catch(error => {
      window.alert('Failed to send message... Try Again.')
    })
  }
}