import React from 'react'
import { Dialog, Typography, makeStyles, IconButton, CircularProgress } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import Login from './Login/Login'
import Register from './Register/Register'
import logo from 'assets/img/iconLogo.png'
import { showAuthModal } from 'redux/General/actions'
import Person from '@material-ui/icons/Person'
import PersonAdd from '@material-ui/icons/PersonAdd'

const useStyles = makeStyles(theme => ({
  dialogTitle: {
    textAlign: 'center', 
    backgroundColor: theme.palette.background.card.default
  },
  titleContainer: {
    padding: '16px 24px'
  },
  logo: {
    height: '50px'
  },
  switchContainer: {
    display: 'flex', 
    cursor: 'pointer'
  },
  switch: {
    width: '50%',
    //border: '1px solid rgba(255,255,255,0.12)',
    height: '75px', 
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default
    },
  },
  switchSelected: {
    color: theme.palette.primary.main,
    width: '50%',
    //border: '1px solid rgba(255,255,255,0.12)',
    height: '75px', 
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default
    },
  },
  icon: {
    color: theme.palette.secondary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default
    },
  },
  iconSelected: {
    color: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default
    },
  },
  loaderContainer: {
    width: '275px',
    height: '130px',
    textAlign: 'center'
  },
  loader: {
    paddingTop: '40px'
  }
}))

export default function AuthModal (props) {
  const classes = useStyles(props)
  const authModalControl = useSelector(store => store.general.showAuthModal)
  const error = useSelector(store => store.auth.error)
  const isVerifying = useSelector(store => store.auth.isVerifying)
  const dispatch = useDispatch()

  return (
    <Dialog open={authModalControl !== false} onClose={() => dispatch(showAuthModal(false))}>
      <div className={classes.dialogTitle}>
        <div className={classes.titleContainer}>
          <img src={logo} alt={'logo'} className={classes.logo} />
        </div>
      </div>
      {!isVerifying?<div>
        <div className={classes.switchContainer}>
          <div className={authModalControl==='login'?classes.switchSelected:classes.switch} onClick={e => dispatch(showAuthModal('login'))}>
            <IconButton edge="start" color='inherit' aria-label="menu" className={classes.margin} onClick={e => dispatch(showAuthModal('register'))}>
              <Person fontSize='large' />
            </IconButton>
            <Typography variant='h6' className={classes.switchTitle}>Login</Typography>
          </div>
          <div className={authModalControl==='register'?classes.switchSelected:classes.switch} onClick={e => dispatch(showAuthModal('register'))}>
            <IconButton edge="start" color='inherit' aria-label="menu" className={classes.margin} onClick={e => dispatch(showAuthModal('register'))}>
              <PersonAdd fontSize='large' />
            </IconButton>
            <Typography className={classes.switchTitle} variant='h6'>Register</Typography>
          </div>
        </div>
        {authModalControl==='login'||authModalControl===false?<Login error={error} isVerifying={isVerifying} />:null}
        {authModalControl==='register'?<Register error={error} />:null}
      </div>:(
        <div className={classes.loaderContainer}>
          <div className={classes.loader}>
            <CircularProgress size={35} />
          </div>
        </div>
      )}
    </Dialog>
  )
}