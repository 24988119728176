import React from 'react'
//material
import { useTheme } from '@material-ui/core'
//canvas
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function DonutChart (props) {
  const {value} = props
  const theme = useTheme()

  const options = {
    theme: "dark2", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    subtitles: [{
    fontFamily: `"Play", sans-serif`,
    text: parseFloat(value*100).toFixed(1) + '%',
      verticalAlign: "center",
      fontSize: 18,
      dockInsidePlotArea: true
    }],
    data: [{
      type: "doughnut",
      yValueFormatString: "#,###'%'",
      dataPoints: [
        { name: "Accuracy", y: value*100, color: theme.palette.primary.main },
        { name: "", y: 100-(value*100), color: theme.palette.background.card },
      ]
    }]
  }

  const containerProps =  {
    height: '150px',
    paddingTop: '0px'
  }

  return (
    <CanvasJSChart containerProps={containerProps} options={options} />
  )
}