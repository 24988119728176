import React from 'react'
import PowerTable from 'components/molecules/PowerTable/PowerTable'
import { useSelector } from 'react-redux'
import { Button, useTheme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const schema = [
  { id: 'title', align: 'left', disablePadding: false, label: 'Project Name' },
  { id: 'id', align: 'left', disablePadding: false, label: 'Project Id' },
  { id: 'type', align: 'left', disablePadding: false, label: 'Project Type' },
  { id: 'checkpoint', align: 'right', disablePadding: false, label: 'Current Checkpoint' },
]

function Projects() {
  const projects = useSelector(store => store.user.projects)
  const fetching = useSelector(store => store.general.fetching)
  const history = useHistory()
  const theme = useTheme()

  function handleClick (e, data) {
     history.push(`/projectv2/${data.id}/type/${data.typeCode}/pg/upload`)
  }
  
  return (
    <React.Fragment>
      <div style={{paddingBottom: '10px'}}>
        <Button variant='outlined' color='primary' onClick={e => history.push('/newproject')}>
          Create New
        </Button>
      </div>

      <PowerTable 
        cardProps={{
          elevation: 0,
          style: {backgroundColor: theme.palette.background.default}
        }} 
        handleClick={handleClick} 
        rows={projects} schema={schema} 
        tableKey='id' 
        isLoading={fetching.projects} 
      />
    </React.Fragment>
  )
}

export default Projects
