//redux
import { store } from 'redux/@store'
import * as projectActions from 'redux/Project/actions'
//axios
import axios from 'axios';
//firebase
import { db } from 'global/firebase'
//other api
import { fetchOff, fetchOn, hideError, setError } from 'redux/General/actions';

export function infer(data, checkpoint) {
  return dispatch => {
    dispatch(projectActions.setInferring(true))
    dispatch(projectActions.setInferData({}))
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId
    const params = {
      user_id: userId,
      project_id: projectId,
      checkpoint: checkpoint,
      data: data,
    }

    axios.get(`${process.env.REACT_APP_API_URL}/inference`, {
      params: params,
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
    }
    ).then(res => {
      console.log(res)
      dispatch(projectActions.setInferring(false))
      dispatch(projectActions.setInferData(res.data))
    }).catch(error => {
      console.log('AXIOS ERROR', error)
      dispatch(projectActions.setInferring(false))
      dispatch(setError('GENERAL', 'Error with inferring model.'))
    })
  }
}

export function inferFile(file, checkpoint) {
  return dispatch => {
    dispatch(projectActions.setInferring(true))
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId
    var formData = new FormData();
    formData.append('file', file)
    formData.append("project_id", projectId);
    formData.append("user_id", userId);
    formData.append("checkpoint", checkpoint);

    axios.post(`${process.env.REACT_APP_API_URL}/file_inference`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      console.log(res)
      dispatch(projectActions.setInferring(false))
      //TODO
      //dispatch file thunk
    }).catch(error => {
      console.log('AXIOS ERROR', error)
      dispatch(projectActions.setInferring(false))
      dispatch(setError('GENERAL', 'Error with inferring model.'))
    })
  }
}

export function deploy(deployType, checkpoint) {
  return dispatch => {
    fetchOn('deploy')
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId

    db.collection('users')
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set({[deployType]: checkpoint}, {merge: true})
    .then((querySnapshot) => {
      fetchOff('deploy')
    })
    .catch(error => {
      dispatch(setError('GENERAL', 'Failed to deploy model.'))
    })
  }
}

export function resetTrainingError() {
  return dispatch => {
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId

    db.collection('users')
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set({trainingError: null}, {merge: true})
    .then((querySnapshot) => {
      dispatch(hideError())
    })
    .catch(error => {
      console.log(error)
    })
  }
}

export function resetProject(projectId) {
  console.log('RESETTING', projectId)
  return dispatch => {
    var userId = store.getState().auth.authUID

    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set(
      {reset: true}, {merge: true}
    ).then(response => {
      dispatch(projectActions.resetModal(false))
    })
    .catch(error => {
      dispatch(projectActions.resetModal(false))
      dispatch(setError('GENERAL', 'Failed to delete project.'))
    })
  }
}