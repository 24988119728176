import React, { useState, useEffect } from 'react'
//material ui
import { Dialog, DialogTitle, DialogContent, List, ListItem, DialogActions, TextField, Button, Typography, makeStyles } from '@material-ui/core'
import Warning from '@material-ui/icons/Warning'
//redux
import { useSelector, useDispatch } from 'react-redux'
import * as projectActions from 'redux/Project/actions'
//services
import { deleteProject } from 'services/Project/project-api'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  warning: {
    paddingRight: '10px',
    paddingTop: '5px'
  }
}))

export default function DeleteDialog (props) {
  const classes = useStyles()
  const { deleteModal, title, projectId } = useSelector(store => store.project)
  const [projectValue, setProjectValue] = useState(null)
  const dispatch = useDispatch()

  useEffect(() => {
    setProjectValue(null)
  }, [projectId])

  return (
    <Dialog open={deleteModal}>
      <div>
        <DialogTitle id="simple-dialog-title">
          <div className={classes.flex}>
            <div className={classes.warning}>
                <Warning color='secondary' />
            </div>
            <Typography color='secondary' variant='h5'>Delete this project?</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <Typography variant='body1'><b>ID:</b> {projectId}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='body1'><b>Name:</b> {title}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='body2'>Confirm you want to delete this project by typing its name.</Typography>
            </ListItem>
            <ListItem>
              <TextField onChange={e => setProjectValue(e.target.value)} value={projectValue} placeholder={title} variant='outlined' color='secondary' fullWidth />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={e => dispatch(projectActions.deleteModal(false))}>Cancel</Button>
            <Button disabled={projectValue!==title} onClick={e => dispatch(deleteProject(projectId))} variant='contained' color='secondary'>Delete</Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}