import React, { useState } from 'react'
//material ui
import { Container, Grid } from '@material-ui/core'
//components
import Header from './components/Header'
import SingleInput from './SingleInput/SingleInput'
import BatchInput from './BatchInput/BatchInput'

function Evaluate(props) {
  const [headerValue, setHeaderValue] = useState(0)
  
  return (
    <React.Fragment>
    <Header setValue={setHeaderValue} value={headerValue} />
    <Container maxWidth='lg' data-aos="fade-up">
      <Grid container spacing={3}>
        {headerValue===0?<SingleInput />:<BatchInput />}
      </Grid>
    </Container>
    </React.Fragment>
  )
}

export default Evaluate
