import React from 'react'
//material ui
import { Typography, makeStyles, Grid, useTheme, useMediaQuery } from '@material-ui/core'
//components
import Image from 'components/atoms/Image'
import LandingVector from 'assets/img/LandingVectorNew.png'
//page components
import HeroLinks from '../HeroLinks'

const useStyles = makeStyles(theme => ({
  marked: {
    height: 4,
    width: 73,
    display: 'block',
    margin: `${theme.spacing(1)}px auto 0`,
    backgroundColor: theme.palette.secondary.main,
  },
  subtitle: {
    color: 'white',
    fontWeight: 200,
    display: 'block',
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(2),
  },
  typed: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  image: {
    width: '100%',
    height: '100%',
    display: 'block',
    maxWidth: 500,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 350,
    },
  },
}))

export default function Hero (props) {
  const classes = useStyles()
  const theme = useTheme()
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  return (
      <Grid container spacing={2} justify='space-between'>
        <Grid item container xs={12} sm={6} alignContent='center' data-aos={'fade-up'}>
          <Grid item xs={12} justify='center'>
            <Typography align={isSm ? 'left' : 'center'} variant='h3' style={{fontWeight: 800, color: 'white'}}>Machine Learning <span className={classes.typed}>Made Simple</span></Typography>
          </Grid>
          <Grid item xs={12}>
          </Grid>
          <Grid item xs={12}>
            <div style={{minHeight: '100px'}}>
            <Typography align={isSm ? 'left' : 'center'} variant='h6' className={classes.subtitle}>Empower your team with automated machine learning to drive better business outcomes.
            </Typography>
            </div>
          </Grid>
          <Grid item container justify={isSm ? 'left' : 'center'} xs={12}>
            <HeroLinks />
          </Grid>
        </Grid>
        <Grid item container justify="center" alignItems="center" xs={12} sm={6} data-aos={'fade-up'}>
            <Image
              src={LandingVector}
              alt="PowerAI"
              className={classes.image}
            />          
        </Grid>
      </Grid>
  )
}
