import { combineReducers } from 'redux';
import { authReducer } from 'redux/Auth/reducer';
import { generalReducer } from 'redux/General/reducer';
import { userReducer } from 'redux/User/reducer';
import { projectReducer } from 'redux/Project/reducer';
import { inferenceReducer } from 'redux/Inference/reducer';
import { dataModelReducer } from 'redux/DataModel/reducer';

export const rootReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
  user: userReducer,
  project: projectReducer,
  inference: inferenceReducer,
  dataModel: dataModelReducer
});
