import { GeneralActions } from 'redux/General/actions';

const initialState = {
  showAuthModal: false,
  fetching: {
    project: false,
    projects: false,
    checkpoints: false,
    apiKeys: false,
    deploy: false,
    files: false,
    infer: false
  },
  errorType: null,
  error: false,
  errorMessage: null
};

export function generalReducer(state = initialState, action) {
  switch (action.type) {
    case GeneralActions.SHOW_AUTH_MODAL:
      return {
        ...state,
        showAuthModal: action.data
      };

    case GeneralActions.FETCH_ON:
      var x = []
      x[action.data] = true
      return {
        ...state,
        fetching: {...state.fetching, [action.data]: true}
      };

    case GeneralActions.FETCH_OFF:
      var x = []
      x[action.data] = false
      return {
        ...state,
        fetching: {...state.fetching, [action.data]: false}
      };

    case GeneralActions.SET_ERROR:
      return {
        ...state,
        error: true,
        errorType: action.errorType,
        errorMessage: action.data
      };

    case GeneralActions.HIDE_ERROR:
      return {
        ...state,
        error: false,
        errorType: 'GENERAL',
        errorMessage: null
      };

    default:
      return state;
  }
}
