import { useTheme } from '@material-ui/core';
import React, {useState, useEffect} from 'react'
//canvas
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function FeatureChart (props) {
  const { data, chartHeight } = props
  const [dataPoints, setDataPoints] = useState([{}])
  const theme = useTheme()

  useEffect(() => {
    if (data) {
      const dp = Object.keys(data).map(item => ({
        y: data[item],
        label: item
      }))
      setDataPoints(dp.sort((a, b) =>  b.y - a.y))
    }
  }, [data])

  const options = {
    theme: "dark2", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    axisX: {
      interval: 1,
      labelFontFamily: `"Play", sans-serif`,
      titleFontFamily: `"Play", sans-serif`,
      reversed: true,
      tickColor: 'white',
      lineColor: 'white',
      gridThickness: 0,
      labelFontColor: 'white',
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    axisY: {
      labelFontFamily: `"Play", sans-serif`,
      titleFontColor: "white",
      tickColor: 'white',
      lineColor: 'white',
      labelFontColor: 'white',
      includeZero: true,
      gridDashType: "dot",
			gridThickness: 1,
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    toolTip: {
      shared: true
    },
    legend: {
      fontColor: 'white'
    },
    data: [{
      type: "bar",
      dataPoints: dataPoints
    }]
  }

  const containerProps =  {
    height: chartHeight?chartHeight:250,
  }

  return (
    <CanvasJSChart containerProps={containerProps} options={options} />
  )
}