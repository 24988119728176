import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Header from 'components/organisms/Header/Header'
import { useHistory } from "react-router-dom"
import Grid from "@material-ui/core/Grid"
import TextField from "@material-ui/core/TextField"
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@material-ui/core"
import { useForm } from 'react-hook-form'
//services
import { addProject } from 'services/Project/project-api'
//redux
import { useDispatch } from 'react-redux'

const modelTypes = [
  {
    title: 'Data Classification',
    description: 'Predict categorical outcomes based on tabular data. (e.g. Yes/No)',
    typeCode: 'dc',
    status: 'Active'
  },
  {
    title: 'Data Regression',
    description: 'Predict numerical estimates based on tabular data. (e.g. Estimated Sale Price)',
    typeCode: 'dr',
    status: 'Coming Soon'
  },
  {
    title: 'Object Detection',
    description: 'Identify objects in images',
    typeCode: 'od',
    status: 'Coming Soon'
  },
  {
    title: 'Natural Language Processing (NLP)',
    description: 'Read, understand and derive meaning from text',
    typeCode: 'nlp',
    status: 'Coming Soon'
  },
]

const useStyles = makeStyles(theme => ({
  centered: {
    textAlign: 'center',
    width: '100%'
  },
  appBar: {
    position: "relative",
  },
  title: {
    paddingBottom: '20px'
  },
  layout: {
    paddingTop: '30px',
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      maxWidth: 600,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    overflowX: 'auto',
    backgroundColor: theme.palette.background.default
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}))

export default function Checkout() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const theme = useTheme()
  const history = useHistory()
  const dispatch = useDispatch()

  const { handleSubmit, setValue, register, watch, errors } = useForm();
  React.useEffect(() => {
    register({name: "typeSelector"}); // custom register Antd input
    setValue('typeSelector', 0)
  }, [register, setValue])

  const onSubmit = data => {
    dispatch(addProject({
      title: data.projectName, 
      type: modelTypes[data.typeSelector].title, 
      dataComplete: false,
      labelComplete: false,
      trainComplete: false,
      checkpoint: 0,
      checkpointGoal: 0,
      typeCode: modelTypes[data.typeSelector].typeCode,
      ignored_columns: [],
      target_column: null,
      data_model: [],
      prodDeployment: null,
      testDeployment: null
    }))
  }

  const handleBack = () => {
    history.goBack()
  }

  return (
    <div id='newproject'>
      <Header headerScroll={{height: 100, color: theme.palette.background.default}} />
      <div className={classes.layout}>
        <div className={classes.paper}>
          <Typography className={classes.title} gutterBottom component="h1" variant="h4" align="center">
            Create Project
          </Typography>
          <React.Fragment>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextField 
                  error={errors.projectName}
                  helperText={errors.projectName && 'Required & Must be less than 50 chars'} 
                  inputRef={register({required: true, maxLength: 20})} 
                  name='projectName'
                  label='Project Name' 
                  fullWidth
                  variant='outlined'
                  color='primary' 
                  placeholder='Project Name' 
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="gender" name="gender1">
                    {modelTypes.map((item, index) => (
                      <FormControlLabel
                        value={item.typeCode}
                        disabled={item.status!=='Active'}
                        control={<Radio />}
                        label={<div><Typography variant='h6'>{item.title}</Typography><Typography variant='caption' gutterBottom >{item.description}</Typography></div>}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <div className={classes.buttons}>
              <Button onClick={handleBack} className={classes.button}>
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                type='submit'
              >
                Create
              </Button>
            </div>
          </form>
          </React.Fragment>
        </div>
      </div>
    </div>
  )
}