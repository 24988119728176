import React from 'react'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { signOutFirebase } from 'services/Auth/auth-api'
import { showAuthModal } from 'redux/General/actions'
//material
import { CircularProgress, Button } from '@material-ui/core'
import Description from '@material-ui/icons/Description'
import Person from '@material-ui/icons/Person'
import ExitToApp from '@material-ui/icons/ExitToApp'
import PersonAdd from '@material-ui/icons/PersonAdd'

//router
import { useHistory } from 'react-router-dom'

function HeaderLinks() {
  const { isVerifying, authenticated } = useSelector(store => store.auth)
  const history = useHistory()
  const dispatch = useDispatch()

  if (isVerifying) {
    return (
      <CircularProgress size={25} />
    )
  } else {
    return(
      authenticated?(
        <React.Fragment>
        <Button startIcon={<Person />} color="inherit" onClick={e => history.push('/user')}>Account</Button>
        <Button startIcon={<ExitToApp />} color="inherit" onClick={e => dispatch(signOutFirebase())}>Logout</Button>
        </React.Fragment>
      ):(
        <React.Fragment>
        <Button startIcon={<Person />} color="inherit" onClick={e => dispatch(showAuthModal('login'))}>Login</Button>
        <Button startIcon={<PersonAdd />} color="inherit" onClick={e => dispatch(showAuthModal('register'))}>Register</Button>
        </React.Fragment>
      )
    )
  }
}

export default HeaderLinks
