import React, { useRef } from 'react'
//components
import Hero from './components/Hero/Hero'
import Header from 'components/organisms/Header/Header'
import ProductValue from './components/About/ProductValue'
import SectionAlternate from 'components/organisms/SectionAlternate/SectionAlternate'
import Section from 'components/organisms/Section'
import HowItWorks from './components/About/HowItWorks'
import Description from './components/About/Description'
import DemoVideo from './components/About/DemoVideo'
import UseCases from './components/About/UseCases'
import Copyright from 'components/molecules/Copyright'
import GetStarted from './components/About/GetStarted'
import ContactForm from 'containers/ContactForm'
//material
import { useTheme, makeStyles, AppBar, Toolbar, IconButton, Drawer } from '@material-ui/core'
import ForumIcon from '@material-ui/icons/Forum'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(12),
    },
  },
  sectionNoPaddingTop: {
    paddingTop: 0,
  },
  gridNoPadding: {
    paddingTop: 0
  },
  shape: {
    background: theme.palette.alternate,
    borderBottomRightRadius: '50%',
    borderBottom: `1px solid rbga(255,255,255, .7)`,
  },
  footer: {
    padding: theme.spacing(2)
  },
  appBarBottom: {
    top: 'auto',
    bottom: 0,
    background: 'transparent',
    boxShadow: 'none',
  },
  toolbarBottom: {
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
  },
  chatIconButton: {
    position: 'absolute',
    right: theme.spacing(3),
    left: 'auto',
    top: theme.spacing(-3),
    background: theme.palette.primary.main,
    width: 55,
    height: 55,
    boxShadow: '0 2px 10px 0 rgba(23,70,161,.11)',
    '&:hover': {
      background: theme.palette.primary.main,
    },
  },
  forumIcon: {
    color: 'white',
    width: 25,
    height: 25,
  },
  contactForm: {
    padding: theme.spacing(3, 2),
    maxWidth: 800,
    margin: '0 auto',
  },
}));

export default function Landing (props) {
  const aboutRef = useRef(null)
  const classes = useStyles()
  const theme = useTheme()

  const [openBottombar, setOpenBottombar] = React.useState(false);

  const handleBottombarOpen = () => {
    setOpenBottombar(true);
  };

  const handleBottombarClose = () => {
    setOpenBottombar(false);
  };

  return (
    <React.Fragment>
      <Header headerScroll={{height: 100, color: theme.palette.background.default}} />
      <Section  className={classes.pagePaddingTop}>
        <Hero />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <ProductValue aboutRef={aboutRef} />
      </Section>
      <SectionAlternate>
        <Description />
      </SectionAlternate>
      <Section>
        <HowItWorks />
      </Section>
      <Section>
        <DemoVideo />
      </Section>
      <Section>
        <UseCases />
      </Section>
      <Section className={classes.sectionNoPaddingTop}>
        <GetStarted />
      </Section>
      <footer className={classes.footer}>
        <Copyright />
      </footer>
      <AppBar position="fixed" className={classes.appBarBottom}>
        <Toolbar disableGutters className={classes.toolbarBottom}>
          <IconButton
            className={classes.chatIconButton}
            onClick={handleBottombarOpen}
          >
            <ForumIcon className={classes.forumIcon} />
          </IconButton>
          <Drawer
            anchor="bottom"
            open={openBottombar}
            onClose={handleBottombarClose}
          >
            <div className={classes.contactForm}>
              <ContactForm />
            </div>
          </Drawer>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  )
}