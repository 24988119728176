import React from 'react'
import { DialogContent, withStyles, AccordionDetails, AccordionSummary, Typography, DialogActions, Button, Accordion } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//redux
import { useDispatch, useSelector } from 'react-redux'
import { resetTrainingError } from 'services/Project/model-api'

const MUIAccordion = withStyles({
  root: {
    border: 'none',
    paddingTop: '15px',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(Accordion);

function TrainingError() {
  const { errorMessage } = useSelector(store=>store.general)
  const trainingError = useSelector(store => store.project.trainingError)
  const [expanded, setExpanded] = React.useState(false)
  const dispatch = useDispatch()
  return (
    <div>
      <DialogContent style={expanded?null:{maxWidth: '300px'}}>
        <Typography gutterBottom>{ errorMessage && errorMessage}</Typography>
        <MUIAccordion elevation={0} expanded={expanded} onChange={e => setExpanded(!expanded)}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon color='secondary' />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          style={{padding: '0px'}}
        >
          <Typography variant='body2' color='secondary'>More Info</Typography>
        </AccordionSummary>
        <AccordionDetails style={{padding: '0px'}}>
          <Typography color='secondary'>
            {trainingError}
          </Typography>
        </AccordionDetails>
      </MUIAccordion>
      </DialogContent>
      <DialogActions >
          <Button fullWidth onClick={e => dispatch(resetTrainingError())} variant='contained' color='primary'>Okay</Button>
      </DialogActions>
    </div>
  )
}

export default TrainingError
