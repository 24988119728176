import React from 'react'
//components
import PowerTable from 'components/molecules/PowerTable/PowerTable'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { deleteFile } from 'services/Project/project-api'
//material ui
import { CircularProgress, IconButton } from '@material-ui/core'
import { CheckCircle, Cancel, GetApp, Delete } from '@material-ui/icons'

const schema = [
  { id: 'statusIcon', align: 'left', disablePadding: false, label: 'Status' },
  { id: 'id', align: 'left', disablePadding: false, label: 'Id' },
  { id: 'name', align: 'left', disablePadding: false, label: 'Filename' },
  { id: 'date_created', align: 'left', disablePadding: false, label: 'Date Created' },
  { id: 'checkpoint_added', align: 'left', disablePadding: false, label: 'Checkpoint Added' },
  { id: 'download', align: 'left', disablePadding: false, label: 'Actions' },
];

function FileTable(props) {
  const rows = useSelector(store => store.project.files)
  const fetching = useSelector(store => store.general.fetching.files)
  const dispatch = useDispatch()

  const data = rows.map((item, index) => ({
    id: index + 1,
    statusIcon: item.status === 'Complete'?(<CheckCircle color='primary' />):item.status==='Pending'?<CircularProgress size={22} />:<Cancel color='secondary' />,
    name: item.original_name,
    ext: item.ext,
    download: (
      <div>
        <GetApp onClick={e => window.open(item.path, "_blank")} />
        <Delete onClick={e => dispatch(deleteFile(item.id))} />
      </div>
    ),
    url: item.path,
    date_created: item.date_added_long,
    checkpoint_added: item.checkpoint_added
  }))
  console.log('test', rows)
  return (
    <PowerTable 
      rows={data} 
      schema={schema}
      cardProps={{
        elevation: 0,
        //style: {backgroundColor: theme.palette.background.default}
      }} 
      tableKey='id' 
      isLoading={fetching} 
      numRows={10}
    />
  )
}

export default FileTable
