import React from 'react'
//material ui
import { Typography, Card, CardContent, useTheme } from '@material-ui/core';
//CanvasJS
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function ColumnChart (props) {
  const {rows, chartOnly} = props
  const theme = useTheme()
  
  var data = {}
  rows.forEach(item => {
    data[item.type] = data[item.type] ? data[item.type] + 1 : 1;
  })

  const chart_data = Object.keys(data).map(item => (
    {label: item, y: data[item]}
  ))

  const options = {
    animationEnabled: false,
    animationDuration: 500,
    //exportEnabled: true,
    backgroundColor: theme.palette.background.card,
    height: 225,
    theme: "dark2", // "light1", "dark1", "dark2"
    legend: {
      fontFamily: `"Play", sans-serif`,
      fontSize: 14,
      fontWeight: 50
    },
    data: [{
        type: "pie",
        showInLegend: false,
				legendText: "{label}",
				toolTipContent: "{label}: <strong>{y}%</strong>",
        indexLabel: "{label}: {y}",		
        startAngle: -90,
        dataPoints: chart_data
    }]
  }

  if (chartOnly) {
    return <CanvasJSChart options = {options} />
  }

  return (
    <Card elevation={5}>
      <CardContent>
        <Typography variant='h6' gutterBottom>Column Breakdown</Typography>
        <CanvasJSChart options = {options} />
      </CardContent>
    </Card>
  )
}