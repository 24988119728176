import React from 'react'
//material ui
import { FormControl, Select, OutlinedInput, InputAdornment, MenuItem, InputLabel, makeStyles} from '@material-ui/core'
import Category from '@material-ui/icons/Category'
import { useCategoryData } from 'utils/hooks'

const useStyles = makeStyles(theme => ({
  changing: {
    backgroundColor: theme.palette.background.card
  }
}))

function CategoryInput(props) {
  const { item, errors, setValue } = props
  const classes = useStyles()
  const categoryData = useCategoryData(item.name)
  if (item.name === 'Gender') {
    console.log(categoryData, item)
  }
  return (
    <div>
      <FormControl
        name={item.name}
        error={errors[item.name]}
        helperText={errors[item.name] && errors[item.name].message} 
        label={item.name}
        //label="Target Column"
        variant='outlined'
        fullWidth
        fontColor='white'
        style={{color: props.fontColor, minWidth: '125px'}} 
      >
        <InputLabel>{item.name}</InputLabel>
          <Select 
            input={<OutlinedInput label={item.name} />}
            className={classes.changing}
            onChange={e => {setValue(item.name, categoryData[e.target.value].x)}}
            startAdornment={
              <InputAdornment position='start' style={{paddingRight: '10px'}}>
                <Category />
              </InputAdornment>
            }
          >
            {categoryData?categoryData.map((item, i) => (
              <MenuItem value={i} name={item.x}>{item.x}</MenuItem>
            )):null}
          </Select>
      </FormControl>
    </div>
  )
}

export default CategoryInput
