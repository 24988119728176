import React from 'react'
//redux
import {useDispatch} from 'react-redux'
//components
import DataDropZone from 'components/organisms/DropZone/DataDropZone'
import UploadDialog from './UploadDialog'
//service
import { uploadDataFile } from 'services/Project/project-api'


export default function Upload (props) {
  const dispatch= useDispatch()
  const { match } = props

  const handleFileAdd = (files) => {
    dispatch(uploadDataFile(files, match.params.projectId))
  }

  return(
    <React.Fragment>
      <UploadDialog />
      <DataDropZone handleFileAdd={handleFileAdd} />
    </React.Fragment>
  )
}