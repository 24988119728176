import React from 'react'
//material ui
import { Grid, Typography, Card, Toolbar, Button, CardContent, List, ListItem, ListItemText, ListItemIcon, IconButton } from '@material-ui/core'
import FindInPage from '@material-ui/icons/FindInPage'
import Publish from '@material-ui/icons/Publish'
import GetApp from '@material-ui/icons/GetApp'
import Description from '@material-ui/icons/Description'
//redux
import { useSelector } from 'react-redux'
//components
import ColumnChart from '../../Explore/ColumnChart'
import CardLoader from 'components/molecules/CardLoader/CardLoader'
//router
import { useHistory, useRouteMatch } from 'react-router-dom'


function DataManagement(props) {
  const { classes } = props
  const dataModel = useSelector(store => store.dataModel.data_model)
  const files = useSelector(store => store.project.files)
  const fetching = useSelector(store => store.general.fetching)
  const history = useHistory()
  const match = useRouteMatch()
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant='h6' gutterBottom color='third'>Data Management</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={5} className={classes.dataCard}>
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1'>Data Types</Typography>
              </Grid>
              <Grid item> 
                <Button onClick={e=>history.push(match.path.split('/').slice(0,-1).join('/') + '/explore')} startIcon={<FindInPage />} variant='text' color='inherit'>View Data</Button>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            <CardLoader
              isLoading={fetching.dataModel}
              placeHolder={dataModel && dataModel.length < 1}
              placeHolderLabel='No Files Uploaded'
            >
              <div style={{paddingTop: '15px'}}>
                <ColumnChart rows={dataModel} chartHeight={300} chartOnly />
              </div>
            </CardLoader>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={5} className={classes.dataCard}>
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1' gutterBottom>Files ({files.length})</Typography>
              </Grid>
              <Grid item>
                <Button onClick={e=>history.push(match.path.split('/').slice(0,-1).join('/') + '/upload')} startIcon={<Publish />} variant='text' color='inherit'>Upload</Button>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent className={classes.cardContent}>
            <CardLoader
              isLoading={fetching.files}
              placeHolder={files.length === 0}
              placeHolderLabel='No Files Uploaded'
            >
            <div style={{height: '100%', overflow: 'auto'}}>
              {files.map(file => (
                <List dense className={classes.list}>
                  <ListItem>
                    <ListItemIcon>
                      <Description />
                    </ListItemIcon>
                    <ListItemText>
                      {file.name}
                    </ListItemText>
                    <IconButton>
                      <GetApp />
                    </IconButton>
                  </ListItem>
                </List>
              ))}
            </div>
          </CardLoader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default DataManagement
