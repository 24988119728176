
export const InferenceActions = {
  SET_INFERENCE_FILES: '@inference/showAuthModal',
  SET_INFERRING: '@inference/setInferring'
};

export function setInferenceFiles(data) {
  return {
    type: InferenceActions.SET_INFERENCE_FILES,
    data: data,
  };
}

export function setInferring(data) {
  return {
    type: InferenceActions.SET_INFERRING,
    data: data,
  };
}