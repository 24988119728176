import React, { useState } from 'react'
//material
import { InputAdornment, DialogContent, List, ListItem, TextField, makeStyles, DialogActions, Button, Typography } from '@material-ui/core'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
import Person from '@material-ui/icons/Person'
//redux
import { useDispatch } from 'react-redux'
import { signUpUser } from 'services/Auth/auth-api'

const useStyles = makeStyles((theme) => ({
  actions: {
      WebkitBoxPack: "center !important",
      MsFlexPack: "center !important",
      justifyContent: "center !important",
      padding: '15px'
  },
  error: {
    color: theme.palette.secondary.main
  },
  errorContainer: {
    padding: '20px',
    maxWidth: '250px',
  },
}))

export default function Register (props) {
  const classes = useStyles()
  const { error } = props
  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleUserRegister();
    }
  };

  const handleUserRegister = () => {
    dispatch(signUpUser({email: email, password: password, username: username}))
  }

  return (
    <React.Fragment>
    <DialogContent>
      <div id='registerform' onKeyDown={handleKeyDown}>
      <List>
        <ListItem>
          <TextField
            fullWidth 
            variant='outlined' 
            color='primary'
            value={username}
            onChange={e => setUsername(e.target.value)}
            //label='Email'
            placeholder='username'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person />
                </InputAdornment>
              )
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth 
            variant='outlined' 
            color='primary' 
            //label='Email'
            onChange={e => setEmail(e.target.value)}
            value={email}
            placeholder='email'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              )
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth 
            variant='outlined' 
            color='primary' 
            //label='Email'
            onChange={e => setPassword(e.target.value)}
            value={password}
            placeholder='password'
            type='password'
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              )
            }}
          />
        </ListItem>
      </List>
      </div>
    </DialogContent>
    <DialogActions className={classes.actions}>
      <Button color='primary' variant='contained' size='large' onClick={e => handleUserRegister()}>Submit</Button>
    </DialogActions>
    {error && <div className={classes.errorContainer}><Typography variant='body2' className={classes.error}>{error.message}</Typography></div>}
    </React.Fragment>
  )
}