import React from 'react'
//material ui
import { Card, CardContent, Grid, Typography, makeStyles, useTheme, CardHeader } from '@material-ui/core'
//redux
import { useSelector } from 'react-redux'
import CardLoader from 'components/molecules/CardLoader/CardLoader';
//Canvas
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const useStyles = makeStyles(theme => ({
  metricCard: {
    //color: 'white',
    //marginTop: '20px',
    //border: '0px',
    padding: '0px',
    backgroundColor: theme.palette.background.card
  }
}))

function ModelAccuracy(props) {
  const{ chartOnly, chartHeight } = props
  const { checkpoints, losses } = useSelector(store => store.project)
  const fetching = useSelector(store => store.general.fetching)
  const classes = useStyles()
  const theme = useTheme()
  const projectData = useSelector(store => store.project)
  const { mostRecentData } = projectData

  const options = {
    theme: "light1", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    chartHeight: '100px',
    axisX: {
      labelFontFamily: `"Play", sans-serif`,
      titleFontFamily: `"Play", sans-serif`,
      tickColor: 'white',
      lineColor: 'white',
      gridDashType: "dot",
			gridThickness: 2,
      labelFontColor: 'white',
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    axisY: {
      //minimum: 0,
      //maximum: 101,
      labelFontFamily: `"Play", sans-serif`,
      titleFontFamily: `"Play", sans-serif`,
      titleFontSize: 16,
      title: 'Accuracy',
      titleFontColor: "white",
      tickColor: 'white',
      lineColor: 'white',
      labelFontColor: 'white',
      includeZero: false,
      gridThickness: 0,
      valueFormatString: "0.00'%'",
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    axisY2: {
      //minimum: 0,
      titleFontFamily: `"Play", sans-serif`,
      labelFontFamily: `"Play", sans-serif`,
      titleFontSize: 16,
      title: "Loss",
      titleFontColor: "white",
      lineColor: "white",
      labelFontColor: "white",
      tickColor: "white",
      includeZero: false
    },
    toolTip: {
      shared: true
    },
    legend: {
      fontColor: 'white',
      fontFamily: `"Play", sans-serif`,
    },
    data: [
      {
        type: "line",
        name: 'Accuracy',
        color: "#21CE99",
        lineColor: "#21CE99",
        yValueFormatString: "0.00'%'",
        showInLegend: true,
        lineThickness: 2,
        dataPoints: checkpoints
      },
      {
        type: "line",
        name: 'Loss',
        showInLegend: true,
        axisYType: "secondary",
        lineThickness: 2,
        dataPoints: losses
      },
    ]
  }

  if (chartOnly) {
    return (
      <CanvasJSChart containerProps={{height: chartHeight?chartHeight:400}} options={options} />
    )
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography gutterBottom variant='h6' color='primary'>Training Stats</Typography>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={0} className={classes.metricCard}>
          <CardHeader title='Model Accuracy' titleTypographyProps={{variant: 'h6', color: 'textSecondary'}} /> 
          <CardContent elevation={0}>
            <CardLoader height={chartHeight?chartHeight:'400px'} isLoading={fetching.checkpoints} placeHolder={checkpoints.length === 0} placeHolderLabel='No Training Data'>
              <div>
                <CanvasJSChart containerProps={{height: '400px'}} options={options} />
              </div>
            </CardLoader>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
        <CardHeader  title='Current Epoch' titleTypographyProps={{variant: 'h6', color: 'textSecondary'}} />
          <CardContent>
          <Typography variant='h6' align='center' className={classes.textItem}>{projectData.checkpoint}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
          <CardHeader title='Accuracy' titleTypographyProps={{variant: 'h6', color: 'textSecondary'}} />
          <CardContent>
            <Typography variant='h6' align='center' className={classes.textItem}>{mostRecentData?parseInt(mostRecentData.accuracy * 100) + '%':'0%'}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
          <CardHeader title='Target Column' titleTypographyProps={{variant: 'h6', color: 'textSecondary'}} />
          <CardContent>
            <Typography variant='h6' align='center' className={classes.textItem}>{projectData.target_column}</Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={6} sm={3}>
        <Card>
        <CardHeader title='Data Split' titleTypographyProps={{variant: 'h6', color: 'textSecondary'}} />
          <CardContent>
            <Typography variant='h6' align='center' className={classes.textItem}>Automatic</Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default ModelAccuracy
