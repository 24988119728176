import React from 'react';
//Material UI
import { Card, Checkbox, FormControlLabel, withStyles, Typography, Grid, IconButton, Container, CardContent } from '@material-ui/core';
import { NavigateBefore, NavigateNext, Close } from '@material-ui/icons';
//Custom Components
import Histogram from './Histogram';
import ValueDist from './ValueDist'
import PowerTabsAlt from 'components/molecules/Tabs/PowerTabsAlt';
import PowerTab from 'components/molecules/Tabs/PowerTab';
import CountUpNumber from 'components/molecules/CountUpNumber';

//router
import { useHistory, useRouteMatch } from 'react-router-dom';
//redux
import { useSelector, useDispatch } from 'react-redux'
//services
import { handleTarget, handleUseful } from 'services/Project/project-api'
import { useCategoryData } from 'utils/hooks';

const styles = theme => ({


    tabBar: {
        marginLeft: -16,
        border: '0px'
        //borderBottom: '.1px solid white'
    },
    columnTitle: {
        fontWeight: 800,
        //paddingLeft: '20px',
    },
    columnText: {
        fontWeight: 500,
        //paddingLeft: '20px'
    }
  });

const mapping = [
  {
    name: 'string/mixed',
    components: [
      {name: 'Value Dist.', component: <ValueDist />}
    ]
  },
  {
    name: 'numeric',
    components: [
      {name: 'Histogram', component: <Histogram />}
    ]
  },
  {
    name: 'categorical',
    components: [
      {name: 'Value Dist.', component: <ValueDist />}
    ]
  },
]

function ColumnInfo (props) {
  const match = useRouteMatch()
  const history = useHistory()
  const dispatch = useDispatch()
  const {classes} = props
  const [value, setValue] = React.useState(0);
  const { projectId, typeCode, target_column, ignored_columns } = useSelector(store => store.project)
  const data_model = useSelector(store => store.dataModel.data_model)
  const rLen = data_model.length
  const row = data_model.filter(item => item.name === match.params.column)[0]
  const rIndex = data_model.findIndex(item => item.name === row.name)

  const categories = useCategoryData(match.params.column)

  if (!row) { return null }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Container maxWidth='lg'>
      <Card className={classes.main}>
        <CardContent>
        <div className={classes.content}>
          <Grid container spacing={1} alignItems='flex-start' justify='flex-start' direction='row'>
            <Grid item xs={12} justify='right' style={{paddingLeft: 0, display: 'flex', justifyContent: 'flex-end'}}>
              <IconButton disabled={rIndex === 0} style={{padding: 0}} onClick={e => {history.push(rIndex && data_model[rIndex - 1].name)}}>
                <NavigateBefore fontSize='large' />
              </IconButton>
              <Typography style={{paddingTop: '3px'}} variant='subtitle1'>{rIndex + 1} of {rLen}</Typography>
              <IconButton disabled={rIndex === rLen - 1} style={{padding: 0}} onClick={e => history.push(rIndex >= 0 && data_model[rIndex + 1].name)}>
                <NavigateNext fontSize='large' />
              </IconButton>
              <IconButton style={{padding: 0}} onClick={e => history.replace(`/project/${projectId}/type/${typeCode}/pg/explore`)}>
                <Close fontSize='large' />
              </IconButton>
            </Grid>
            <Grid item xs={12}>
                <Typography variant='h4' className={classes.columnTitle}>{row.name}</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={row.type!=='categorical'}
                    checked={row.name===target_column}
                    inputProps={{ 'aria-labelledby': row.name }}
                    onClick={e => {e.preventDefault(); dispatch(handleTarget(row.name))}}
                  />
                } 
                label="Target" 
                labelPlacement='start'
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={ignored_columns?ignored_columns.includes(row.name)?true:false:false}
                    name="checkedA" 
                    onClick={e => {e.preventDefault(); dispatch(handleUseful(row.name))}}
                  />
                } 
                label="Exclude" 
                labelPlacement='start'
              />
            </Grid>

            <Grid item container justify='flex-start' xs={12}>
              <Grid item xs={3}>
                <CountUpNumber
                  textValue={row.type}
                  label="Type"
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <CountUpNumber
                  end={row.count}
                  label="Count"
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <CountUpNumber
                  end={row.unique}
                  label="Unique Vals."
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <CountUpNumber
                  end={row.mean}
                  label="Mean"
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <CountUpNumber
                  end={row.min}
                  label="Min"
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <CountUpNumber
                  end={row.max}
                  label="Max"
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
              <Grid item xs={3}>
                <CountUpNumber
                  end={row.std}
                  label="Std. Dev."
                  textComponent='body1'
                  textColor="primary"
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className={classes.tabBar}>
                <PowerTabsAlt
                  colorAlternate
                  value={value}
                  indicatorColor="primary"
                  textColor="primary"
                  onChange={handleChange}
                  aria-label="disabled tabs example"
                >
                  {mapping.filter(d => d.name === row.type)[0].components
                  .map(item => (
                    <PowerTab label={item.name} />
                  ))}
                </PowerTabsAlt>
              </div>
            </Grid>
            {value===0 && row.type === 'numeric'?
            (<Grid item xs={12}>
              {<Histogram categories={categories} columnName={row['name']} />}
            </Grid>):null}

            {value===0 && row.type === 'categorical'?
            (<Grid item xs={12}>
              {<Histogram categories={categories} columnName={row['name']} />}
            </Grid>):null}

            {value===0 && row.type === 'string/mixed'?
            (<Grid item xs={12}>
                {<ValueDist words={categories} />}
            </Grid>):null}

            {value===1?
            (<Grid item xs={12}>
                {value}
            </Grid>):null}
            {value===2?
            (<Grid item xs={12}>
                {value}
            </Grid>):null}
          </Grid>
        </div>
        </CardContent>
      </Card>
      </Container>
    </React.Fragment>
  )
}

export default withStyles(styles)(ColumnInfo);