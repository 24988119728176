
export const GeneralActions = {
  SHOW_AUTH_MODAL: '@general/showAuthModal',
  FETCH_ON: '@general/fetchOn',
  FETCH_OFF: '@general/fetchOFF',
  SET_ERROR: '@general/setError',
  HIDE_ERROR: '@general/hideError'
};

export function showAuthModal(data) {
  return {
    type: GeneralActions.SHOW_AUTH_MODAL,
    data: data,
  };
}

export function fetchOn(data) {
  return {
    type: GeneralActions.FETCH_ON,
    data: data,
  };
}

export function fetchOff(data) {
  return {
    type: GeneralActions.FETCH_OFF,
    data: data,
  };
}

export function setError(errorType, data) {
  return {
    type: GeneralActions.SET_ERROR,
    errorType: errorType,
    data: data,
  };
}

export function hideError(data) {
  return {
    type: GeneralActions.HIDE_ERROR,
  };
}