import { useTheme } from '@material-ui/core';
import React, { useRef } from 'react'
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function ProbChart(props) {
  const { data } = props
  const theme = useTheme()
  const chart = useRef()

  //const newData = data.map((item, index) => ({label: index, y: item}))
  var newData = Object.keys(data).map((item, index) => ({label: item, y: data[item]}))

  const options = {
    theme: "light1", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    axisX: {
      title: 'Outcomes',
      titleFontColor: "white",
      reversed: true,
      tickColor: 'white',
      lineColor: 'white',
      gridDashType: "dot",
      gridThickness: 0,
      includeZero: 'false',
      labelFontColor: 'white',
      valueFormatString: "#,##0.##",
      labelFontSize: 16,
      interval: 1,
      labelAutoFit: true,
      //labelAngle: -90,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        color: 'white'
      }
    },
    axisY: {
      minimum: 0,
      maximum: 1,
      title: 'Probability',
      titleFontColor: "white",
      valueFormatString: "0.00%",
      tickColor: 'white',
      lineColor: 'white',
      labelFontColor: 'white',
      includeZero: false,
      gridThickness: 0,
      //logarithmic: true,
      titleFontSize: 16,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        color: 'white'
      }
    },

    legend: {
      fontColor: 'white'
    },
    data: [
      {
        indexLabel: "{y}",
        indexLabelFontColor: "#fffff",
        indexLabelPlacement: "inside",
        type: "bar",
        name: 'Outcomes',
        color: "#21CE99",
        lineColor: "#21CE99",
        yValueFormatString: "0.00%",
        dataPoints: newData
      },
    ]
  }


  return (
    <div style={{paddingTop: '30px'}}>
      <CanvasJSChart containerProps={{maxHeight: '350px'}} options={options} ref={chart} />
    </div>
  )
}

export default ProbChart
