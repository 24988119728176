//firebase
import { db, auth } from 'global/firebase'
import firebase from 'firebase'
//redux
import {store} from 'redux/@store'
import { fetchOn, fetchOff } from 'redux/General/actions'
import { setAPIKeys, setProjects } from 'redux/User/actions'
//services
import { verifyAuth } from 'services/Auth/auth-api'


export function fetchProjects() {
  return dispatch => {
    dispatch(fetchOn('projects'))
    var userId = store.getState().auth.authUID

    db.collection("users")
    .doc(userId)
    .collection("projects")
    .get()
    .then(results => {
      const data = results.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      dispatch(setProjects(data.filter(item => item.deleted !== true)))
      dispatch(fetchOff('projects'))
    })
  }
}

export function fetchAPIKeys() {
  return dispatch => {
    dispatch(fetchOn('apiKeys'))
    var userId = store.getState().auth.authUID

    db.collection("users")
    .doc(userId)
    .collection("api_keys")
    .get()
    .then(results => {
      const data = results.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      dispatch(setAPIKeys(data))
      dispatch(fetchOff('apiKeys'))
    })
  }
}

export function updateUser (username) {
  return dispatch => {
    const authUID = store.getState().auth.authUID
    db.collection("users")
    .where('username', '==', username)
    .limit(1)
    .get()
    .then(results => {
      if (results.docs.length > 0) {
        alert('Username already exists.')
      } else {
        db.collection("users")
        .doc(authUID)
        .set({
          username: username
        }, {merge: true})
        .then(response => {
          dispatch(verifyAuth())
        })
        .catch(error => {
          alert('Failed to update.  Try agin.')
        })
      }
    })
  }
}

export function updateEmail(newValue) {
  return dispatch => {
    var user = auth.currentUser;
    const { authUID } = store.getState().auth
  
    user.updateEmail(newValue).then(() => {
      // Update successful.
      db.collection("users")
      .doc(authUID)
      .set({
        email: newValue
      }, {merge: true})
      .then(response => {
        dispatch(verifyAuth())
      })
      .catch(error => {
        alert('Failed to update.  Try agin.')
      })
  
    }).catch((error) => {
      // An error happened.
      if (error.code === 'auth/requires-recent-login') {
        const userProvidedPassword = window.prompt("Password:");
        const credential = firebase.auth.EmailAuthProvider.credential(
          user.email, 
          userProvidedPassword
        );
        
        // Prompt the user to re-provide their sign-in credentials
        user.reauthenticateWithCredential(credential).then(function() {
          // User re-authenticated.
          dispatch(updateEmail(newValue))
        }).catch(function(error) {
          // An error happened.
          alert(error)
        });
      } else {
        alert(error)
      }
    });
  }
}

export function updateAPIKey(keyId, status) {
  return dispatch => {
    dispatch(fetchOn('apiKeys'))
    var userId = store.getState().auth.authUID

    db.collection("users")
    .doc(userId)
    .collection("api_keys")
    .doc(keyId)
    .set({
      status: status
    }, {merge: true})
    .then(response => {
      dispatch(fetchAPIKeys())
      dispatch(fetchOff('apiKeys'))
    })
    .catch(error => {
      console.log(error)
      dispatch(fetchOff('apiKeys'))
    })
  }
}