import { ProjectActions } from 'redux/Project/actions';

const initialState = {
  files: [],
  target_column: null,
  ignored_columns: [],
  importance: {},
  isUploadingFiles: false,
  uploadModal: false,
  uploadFiles: [],
  checkpoints: [],
  losses: null,
  mostRecentData: null,
  prevData: null,
  inferring: false,
  inferData: {},
  deleteModal: false,
  resetModal: false,
  isDeleteing: false,
  prodDeployment: null,
  testDeployment: null
};

export function projectReducer(state = initialState, action) {
  switch (action.type) {

    case ProjectActions.SET_PROJECT_DATA:
      return {
        ...state,
        ...action.data,
      };

    case ProjectActions.UPLOAD_MODAL:
      return {
        ...state,
        uploadModal: action.data
      };

    case ProjectActions.IS_UPLOADING_FILES:
      return {
        ...state,
        isUploadingFiles: action.data
      };

    case ProjectActions.SET_UPLOAD_FILES:
      return {
        ...state,
        uploadFiles: action.data,
        isUploadingFiles: false
      };

    case ProjectActions.SET_FILES:
      return {
        ...state,
        files: action.data
      };

    case ProjectActions.SET_CHECKPOINTS:
      return {
        ...state,
        checkpoints: action.data
      };

    case ProjectActions.SET_LOSSES:
      return {
        ...state,
        losses: action.data
      };

    case ProjectActions.SET_MOST_RECENT_DATA:
      return {
        ...state,
        mostRecentData: action.data
      };

    case ProjectActions.SET_PREV_DATA:
      return {
        ...state,
        prevData: action.data
      };

      case ProjectActions.SET_INFERRING:
        return {
          ...state,
          inferring: action.data
        };

      case ProjectActions.SET_INFER_DATA:
        return {
          ...state,
          inferData: action.data
        };

      case ProjectActions.DELETE_MODAL:
        return {
          ...state,
          deleteModal: action.data
        };

      case ProjectActions.RESET_MODAL:
        return {
          ...state,
          resetModal: action.data
        };

      case ProjectActions.IS_DELETING:
        return {
          ...state,
          isDeleting: action.data
        };

    default:
      return state;
  }
}
