import React from 'react'
import { Toolbar, makeStyles } from '@material-ui/core'
import PowerTabs from 'components/molecules/Tabs/PowerTabs'
import PowerTab from 'components/molecules/Tabs/PowerTab'

const useStyles = makeStyles(theme => ({
  header: {
    width: '100% + 0px',
    backgroundColor: theme.palette.background.default,
    margin: '-48px -16px 48px -16px',
    height: '48px',
    borderBottom: '1px solid rgba(255,255,255,0.12)',
    overflowX: 'auto',
    color: 'rgba(255, 255, 255, 0.7)'
  },
}))

export default function Header(props) {
  const { value, setValue } = props
  const classes = useStyles()

  return (
    <Toolbar className={classes.header}>
      <PowerTabs className={classes.tabs} value={value} textColor='primary' indicatorColor="primary" onChange={(e, newValue) => setValue(newValue)}>
        <PowerTab label='Single' />
        <PowerTab label='Batch' />
      </PowerTabs>
    </Toolbar>
  )
}
