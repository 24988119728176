import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { theme } from 'global/theme.js'
import { useDispatch } from 'react-redux'
import { setError } from 'redux/General/actions'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 3,
  borderRadius: 2,
  borderColor: 'rgba(255,255,255,0.60)',
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.card,
  color: 'rgba(255,255,255,0.60)',
  fontWeight: 500,
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const activeStyle = {
  borderColor: '#2196f3',
  color: '#2196f3'
};

const acceptStyle = {
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
};

export default function SetupDropZone(props) {
  const dispatch = useDispatch()
  const onDrop = useCallback(acceptedFiles => {
    if (acceptedFiles.length === 0){
      return;
    } else if (acceptedFiles.length > 1) {
      dispatch(setError('GENERAL', 'Max upload of (1 file) exceeded.'))
    } else {
      props.handleFileAdd(acceptedFiles)
    }

  }, [props, dispatch])

  const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
  } = useDropzone({onDrop, accept: 'text/csv'});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container" style={{height: '100%'}}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div style={{ margin: 'auto', textAlign: 'center' }}>
          <p style={{ fontSize: '25px'}}>Add dataset here</p>
        </div>
      </div>
    </div>
  );
}