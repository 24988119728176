import React from 'react';
//class handling
import PropTypes from 'prop-types';
import clsx from 'clsx';
//material ui
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import Public from '@material-ui/icons/Public';
import TimerIcon from '@material-ui/icons/Timer';
import { Typography } from '@material-ui/core';
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';
import Pageview from '@material-ui/icons/Pageview'
import AddBox from '@material-ui/icons/AddBox'
import BlurLinear from '@material-ui/icons/BlurLinear';
//logo
import logo from 'assets/img/fullLogo.png'
//router
import { useHistory, useRouteMatch } from 'react-router-dom'


const categories = {
  dc: [
    {
      id: 'Manage Data',
      children: [
        { id: 'Upload', icon: <AddBox />, pageName: 'upload' },
        { id: 'Expore', icon: <Pageview />, pageName: 'explore' },
      ],
    },
    {
      id: 'Modeling',
      children: [
        { id: 'Train', icon: <BlurLinear />, pageName: 'train' },
        { id: 'Evaluate', icon: <TimerIcon />, pageName: 'evaluate' }
      ]
    },
    {
      id: 'Deployment',
      children: [
        { id: 'Deploy', icon: <Public />, pageName: 'deploy' },
      ],
    },
  ]
}

const styles = (theme) => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    cursor: 'pointer',
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    //backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: theme.palette.primary.main,
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  logo: {
    fontSize: 18,
  }
});

function Navigator(props) {
  const { classes, onClose, ...other } = props;
  const match = useRouteMatch()
  const history = useHistory()

  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem onClick={e => {history.push('/'); onClose()}} className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
          <a href="/"><img style={{height: 35, paddingTop: '9px', paddingRight: '13px'}} src={logo} alt="Logo" /></a>
        </ListItem>
        <ListItem onClick={e => history.push('/user')} button className={clsx(classes.item, classes.itemCategory)}>
          <ListItemIcon className={classes.itemIcon}>
            <ArrowBackIos />
          </ListItemIcon>
          <ListItemText classes={{primary: classes.itemPrimary,}}>
            All Projects
          </ListItemText>
        </ListItem>
        <ListItem onClick={e => {history.push(`/project/${match.params.projectId}/type/${match.params.typeCode}/pg/home`); onClose()}} className={clsx(classes.item, classes.itemCategory, match.params.page === 'home' && classes.itemActiveItem)}>
          <ListItemIcon className={classes.itemIcon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText classes={{primary: classes.itemPrimary,}}>
            Project Overview
          </ListItemText>
        </ListItem>
        {categories[match.params.typeCode].map(({ id, children }) => (
          <React.Fragment key={id}>
            <ListItem className={classes.categoryHeader}>
              <ListItemText
                classes={{
                  primary: classes.categoryHeaderPrimary,
                }}
              >
                {id}
              </ListItemText>
            </ListItem>
            {children.map(({ id: childId, icon, pageName }) => (
              <ListItem
                key={childId}
                button
                className={clsx(classes.item, pageName.includes(match.params.page) && classes.itemActiveItem)}
                onClick={e => {history.push(`/project/${match.params.projectId}/type/${match.params.typeCode}/pg/${pageName}`); onClose()}}
              >
                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                <ListItemText
                  classes={{
                    primary: classes.itemPrimary,
                  }}
                >
                  {childId}
                </ListItemText>
              </ListItem>
            ))}

            <Divider className={classes.divider} />
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

Navigator.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Navigator);