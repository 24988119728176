import React, { useState } from 'react'
import { Typography, TextField, makeStyles, Button } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { updateUser, updateEmail } from 'services/User/user-api'

const useStyles = makeStyles(theme => ({
  title: {
    paddingBottom: '10px'
  },
  value: {
    color: 'white',
    fontSize: '20px'
  },
  button: {
    margin: '15px 7px 40px 0px',
  }
}))

function Account(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const user = useSelector(store => store.auth.authUser)
  const email = useSelector(store => store.auth.email)
  const [state, setState] = useState({
    userEdit: false,
    emailEdit: false,
    email: '',
    user: '',
  })

  return (
    <div>
      <Typography className={classes.title} variant='h6' color='primary'>Username</Typography>
      {state.userEdit?(
        <React.Fragment>
          <TextField variant='outlined' value={state.user} onChange={e => setState({...state, user: e.target.value})} />
          <div>
            <Button className={classes.button} variant='outlined' color='secondary' onClick={e => setState({...state, userEdit: false})}>Cancel</Button>
            <Button className={classes.button} variant='contained' color='primary' onClick={e => {setState({...state, userEdit: false}); dispatch(updateUser(state.user))}}>Okay</Button>
          </div>
        </React.Fragment>
      ):(
        <React.Fragment>
          <Typography variant='subtitle1' className={classes.value}>{user}</Typography>
          <Button size='small' variant='outlined' color='primary' className={classes.button} onClick={e => setState({...state, user: user, userEdit: true})}>Edit</Button>
        </React.Fragment>
      )}
      
      <Typography className={classes.title} variant='h6' color='primary'>Email</Typography>
      {state.emailEdit?(
        <React.Fragment>
          <TextField variant='outlined' value={state.email} onChange={e => setState({...state, email: e.target.value})} />
          <div>
            <Button className={classes.button} variant='outlined' color='secondary' onClick={e => setState({...state, emailEdit: false})}>Cancel</Button>
            <Button className={classes.button} variant='contained' color='primary' onClick={e=> {setState({...state, emailEdit: false}); dispatch(updateEmail(state.email))}}>Okay</Button>
          </div>
        </React.Fragment>
      ):(
        <React.Fragment>
          <Typography variant='subtitle1' className={classes.value}>{email}</Typography>
          <Button size='small' variant='outlined' color='primary' className={classes.button} onClick={e => setState({...state, email: email, emailEdit: true})}>Edit</Button>
        </React.Fragment>
      )}    </div>
  )
}

export default Account
