import { withStyles, Tabs } from '@material-ui/core'

const PowerTabs = withStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.card,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

export default PowerTabs