import React from 'react'
import PowerTable from 'components/molecules/PowerTable/PowerTable'
import { useSelector, useDispatch } from 'react-redux'
import { FormControlLabel, Switch, Button } from '@material-ui/core'
import { updateAPIKey } from 'services/User/user-api'

const schema = [
  { id: 'name', align: 'left', disablePadding: false, label: 'Key Name' },
  { id: 'id', align: 'left', disablePadding: false, label: 'Project Id' },
  { id: 'date_created', align: 'left', disablePadding: false, label: 'Date Created' },
  { id: 'action', align: 'left', disablePadding: false, label: 'Status' },
]

function ApiKeys(props) {
  const dispatch = useDispatch()
  const apiKeys = useSelector(store => store.user.apiKeys)
  const fetching = useSelector(store => store.general.fetching)
  const newData = apiKeys.map(item => ({
    ...item,
    date_created: item.date_created.toDate().toLocaleString(),
    action: (
      <FormControlLabel
      control={
        <Switch
          checked={item.status === 'Active'?true:false}
          name="checkedA" 
          onClick={e => dispatch(updateAPIKey(item.id, item.status==='Active'?'Deactivated':'Active'))}
        />
      }
    />
    )
  }))
  return (
    <React.Fragment>
      <div style={{paddingBottom: '10px'}}>
        <Button variant='outlined' color='primary'>Create New</Button>
      </div>
      <PowerTable rows={newData} schema={schema} tableKey={'id'} isLoading={fetching.apiKeys} />
    </React.Fragment>
  )
}

export default ApiKeys