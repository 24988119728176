import React, { useEffect } from 'react'
//router
import { Route } from 'react-router-dom'
//material
import { makeStyles, Hidden } from '@material-ui/core'
//components
import Navigator from './components/Navigator'
import Copyright from 'components/molecules/Copyright'
import Header from './components/Header'
//redux
import { useDispatch } from 'react-redux'
//components
import Upload from './DataClassification/Upload/Upload'
import Explore from './DataClassification/Explore/Explore'
import ColumnInfo from './DataClassification/ColumnInfo/ColumnInfo'
import Train from './DataClassification/Train/Train'
import Evaluate from './DataClassification/Evaluate/Evaluate'
import Deploy from './DataClassification/Deploy/Deploy'
import Home from './DataClassification/Home/Home'
//api
import DeleteDialog from 'components/organisms/DeleteDialog/DeleteDialog'
import ResetDialog from 'components/organisms/ResetDialog/ResetDialog'
import { useProjectData, useCheckpointData, useDataModel, useDataFiles } from 'utils/hooks'

const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  root: {
    //display: 'flex',
    height: '100%',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    height: '100%',
    [theme.breakpoints.up("md")]: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`
    }
  },
  main: {
    minHeight: '100%',
    //flex: 1,
    padding: theme.spacing(6, 2),
    //background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    //background: '#eaeff1',
  },
}))

function Project(props) {
  const { match } = props
  const classes = useStyles()
  const [mobileOpen, setMobileOpen] = React.useState(false);

  useProjectData(match.params.projectId)
  useCheckpointData(match.params.projectId)
  useDataModel(match.params.projectId)
  useDataFiles(match.params.projectId)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className={classes.root}>
      <nav className={classes.drawer}>
        <Hidden mdUp implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth }, elevation: 5 }}
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
          />
        </Hidden>
        <Hidden smDown implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth }, elevation: 5 }} onClose={handleDrawerToggle} />
        </Hidden>
      </nav>
      <div className={classes.app}>
        <Header onDrawerToggle={handleDrawerToggle} />
        <main className={classes.main}>
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/home`} component={() => <Home />} />
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/upload`} component={() => <Upload match={match} />} />
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/explore`} component={() => <Explore match={match} />} />
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/explore/column/:column`} component={() => <ColumnInfo />} />
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/train`} component={() => <Train />} />
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/evaluate`} component={() => <Evaluate />} />
          <Route exact path={`/project/${match.params.projectId}/type/dc/pg/deploy`} component={() => <Deploy />} />
        </main>
        <footer className={classes.footer}>
          <Copyright />
        </footer>
      </div>
      <DeleteDialog />
      <ResetDialog />
    </div>
  )
}

export default Project
