import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import { Grid, Button, colors } from '@material-ui/core';
import Image from 'components/atoms/Image';
import SectionHeader from 'components/molecules/SectionHeader';
import PlaceHolder from 'assets/img/dashboard.svg'
import PlayCircleFilled from '@material-ui/icons/PlayCircleFilled'

const useStyles = makeStyles(theme => ({
  root: {},
  video: {
    position: 'relative',
  },
  videoCover: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    background: colors.indigo[500],
    opacity: '0.3',
    borderRadius: theme.spacing(1),
    cursor: 'pointer',
  },
  videoPlayButton: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    fontSize: 70,
    zIndex: 1300,
    boxShadow: '0 8px 21px 0 rgba(30,76,165,.2)',
    borderRadius: '100%',
    cursor: 'pointer'
  },
}));

const DemoVideo = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} data-aos="fade-up" {...rest}>
      <Grid
        container
        justify="space-between"
        spacing={isMd ? 4 : 2}
        direction="row-reverse"
      >
        <Grid item xs={12} md={6} data-aos={'fade-up'}>
          <Grid container alignItems="flex-start">
            <Grid item xs={12}>
              <SectionHeader
                title={
                  <span>
                    See For Yourself
                  </span>
                }
                subtitle="We help businesses cut to the chase in implementing machine learning."
                ctaGroup={[
                  <Button
                    variant="outlined"
                    color="primary"
                    size={isMd ? 'large' : 'medium'}
                  >
                    View Demo
                  </Button>,
                ]}
                align={isMd ? 'left' : 'center'}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          justify="center"
          xs={12}
          md={6}
          data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="1000"
        >
          <div className={classes.video}>
            <Image src={PlaceHolder} alt="Dashboard" />
            <PlayCircleFilled
              className={clsx(classes.videoPlayButton)}
            />
            <div className={classes.videoCover} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

DemoVideo.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default DemoVideo;
