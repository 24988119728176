
export const ProjectActions = {
  SET_PROJECT_DATA: '@project/setProject',
  UPLOAD_MODAL: '@project/uploadModal',
  IS_UPLOADING_FILES: '@project/isUploadingFiles',
  SET_UPLOAD_FILES: '@project/setUploadFiles',
  SET_FILES: '@project/setFiles',
  SET_CHECKPOINTS: '@project/setCheckpoints',
  SET_MOST_RECENT_DATA: '@project/setMostRecentData',
  SET_LOSSES: '@project/setLosses',
  SET_PREV_DATA: '@project/setPrevData',
  SET_INFERRING: '@project/setInferring',
  SET_INFER_DATA: '@project/setInferData',
  DELETE_MODAL: '@project/deleteModal',
  RESET_MODAL: '@project/resetModal',
  IS_DELETING: '@project/isDeleting',
};

export function setProjectData(data) {
  return {
    type: ProjectActions.SET_PROJECT_DATA,
    data: data,
  };
}

export function uploadModal (data) {
  return {
    type: ProjectActions.UPLOAD_MODAL,
    data: data
  }
}

export function isUploadingFiles (data) {
  return {
    type: ProjectActions.IS_UPLOADING_FILES,
    data: data
  }
}

export function setUploadFiles (data) {
  return {
    type: ProjectActions.SET_UPLOAD_FILES,
    data: data
  }
}

export function setFiles (data) {
  return {
    type: ProjectActions.SET_FILES,
    data: data
  }
}

export function setCheckpoints(data) {
  return {
    type: ProjectActions.SET_CHECKPOINTS,
    data: data
  }
}

export function setLosses(data) {
  return {
    type: ProjectActions.SET_LOSSES,
    data: data
  }
}

export function setMostRecentData(data) {
  return {
    type: ProjectActions.SET_MOST_RECENT_DATA,
    data: data
  }
}

export function setPrevData(data) {
  return {
    type: ProjectActions.SET_PREV_DATA,
    data: data
  }
}

export function setInferring(data) {
  return {
    type: ProjectActions.SET_INFERRING,
    data: data
  }
}

export function setInferData(data) {
  return {
    type: ProjectActions.SET_INFER_DATA,
    data: data
  }
}

export function deleteModal(data) {
  return {
    type: ProjectActions.DELETE_MODAL,
    data: data
  }
}

export function resetModal(data) {
  return {
    type: ProjectActions.RESET_MODAL,
    data: data
  }
}

export function isDeleting(data) {
  return {
    type: ProjectActions.IS_DELETING,
    data: data
  }
}