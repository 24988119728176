import React, { useEffect } from 'react'
//components
import PowerTable from 'components/molecules/PowerTable/PowerTable'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { fetchOn, fetchOff } from 'redux/General/actions'
import { setInferenceFiles } from 'redux/Inference/actions'
//material ui
import GetApp from '@material-ui/icons/GetApp'
//services
//firebase
import { db } from 'global/firebase'
import { Link } from 'react-router-dom'

const schema = [
  { id: 'name', align: 'left', disablePadding: false, label: 'File Name' },
  { id: 'checkpoint', align: 'left', disablePadding: false, label: 'Checkpoint' },
  { id: 'date_created', align: 'left', disablePadding: false, label: 'Date Created' },
  { id: 'link', align: 'right', disablePadding: false, label: 'Download' }
];

function FileTable() {
  const { projectId } = useSelector(store => store.project)
  const userId = useSelector(store => store.auth.authUID)
  const { inferring, files } = useSelector(store => store.inference)
  const fetching = useSelector(store => store.general.fetching)
  const dispatch = useDispatch()
  
  useEffect(() => {
    dispatch(fetchOn('infer'))
    const unsubscribe = db.collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection("inference_files")
    .onSnapshot(function(snap) {
      if (snap.size) {
        const files = snap.docs.map(doc => doc.data())
        dispatch(setInferenceFiles(files))
        dispatch(fetchOff('infer'))
      } else {
        dispatch(setInferenceFiles([]))
        dispatch(fetchOff('infer'))
      }
      
    }, function (error) {window.alert(error)})

    return () => {
      console.log('unsub')
      unsubscribe()
    }
  }, [dispatch, projectId, userId])

  function handleClick (e, data) {
    window.open(data.path, "_blank")
 }
  const newData = files.map(file => ({
    ...file,
    link: <GetApp />
  }))

  return (
    <React.Fragment>
      <PowerTable 
        isLoading={inferring || fetching.infer} 
        cardProps={{elevation: 0}} rows={newData} 
        schema={schema} 
        tableKey='name' 
        numRows={5}
        handleClick={handleClick}
        defaultOrder='desc'
      />
    </React.Fragment>
  )
}

export default FileTable
