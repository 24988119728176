import React from 'react'
//components
import PowerTable from 'components/molecules/PowerTable/PowerTable'
//redux
import { useSelector, useDispatch } from 'react-redux'
//material ui
import { Checkbox } from '@material-ui/core'
//services
import { deploy } from 'services/Project/model-api'

const schema = [
  { id: 'test', align: 'left', disablePadding: false, label: 'Test' },
  { id: 'prod', align: 'left', disablePadding: false, label: 'Prod' },
  { id: 'checkpoint', align: 'left', disablePadding: false, label: 'Checkpoint' },
  { id: 'accuracy', align: 'left', disablePadding: false, label: 'Accuracy' },
  { id: 'loss', align: 'left', disablePadding: false, label: 'Loss' },
  { id: 'fileName', align: 'left', disablePadding: false, label: 'Model Name' },
];

function ExploreTable() {
  const rows = useSelector(store => store.project.checkpoints)
  const testDeployment = useSelector(store => store.project.testDeployment)
  const prodDeployment = useSelector(store => store.project.prodDeployment)
  const fetching = useSelector(store => store.general.fetching)
  //const project = useSelector(store => store.project)
  const dispatch = useDispatch()

  const data = rows.map(item => ({
    test: (
      <Checkbox
        disabled={fetching.deploy}
        checked={item.checkpoint === testDeployment}
        inputProps={{ 'aria-labelledby': item.name }}
        onClick={e => {e.preventDefault(); dispatch(deploy('testDeployment', item.checkpoint))}}
      />
    ),
    prod: (
      <Checkbox
        disabled={fetching.deploy}
        checked={item.checkpoint === prodDeployment}
        inputProps={{ 'aria-labelledby': item.name }}
        onClick={e => {e.preventDefault(); dispatch(deploy('prodDeployment', item.checkpoint))}}
      />
      ),
    checkpoint: item.checkpoint,
    accuracy: (item.accuracy * 100).toFixed(2) + '%',
    loss: item.loss.toFixed(2),
    fileName: item.fileName,
  })).sort((a, b) => b.checkpoint > a.checkpoint)

  return (
    <React.Fragment>
      <PowerTable rows={data} schema={schema} tableKey='name' isLoading={fetching.checkpoints} />
    </React.Fragment>
  )
}

export default ExploreTable
