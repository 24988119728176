import React, { useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { theme } from 'global/theme.js'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  borderWidth: 3,
  borderRadius: 2,
  borderColor: '#bdbdbd',
  borderStyle: 'dashed',
  backgroundColor: theme.palette.background.card,
  color: 'white',
  fontWeight: 500,
  outline: 'none',
  transition: 'border .24s ease-in-out',
  minHeight: '125px',
  cursor: 'pointer'
};

const activeStyle = {
  borderColor: '#2196f3',
  color: '#2196f3'
};

const acceptStyle = {
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main
};

const rejectStyle = {
  borderColor: '#ff1744',
  color: '#ff1744'
};

export default function StyledDropzone(props) {
  const onDrop = useCallback(acceptedFiles => {
    if (!acceptedFiles.length < 1){
      props.handleFileAdd(acceptedFiles)
    }

  }, [props])

  const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
  } = useDropzone({onDrop, accept: 'text/csv'});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isDragActive ? activeStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isDragActive,
    isDragReject,
    isDragAccept
  ]);

  return (
    <div className="container" style={{height: '100%'}}>
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <div style={{margin: 'auto', textAlign: 'center'}}>
          <p style={{fontSize: '25px', color: '#bdbdbd'}}>Drag dataset here</p>
        </div>

      </div>
    </div>
  );
}