import { InferenceActions } from 'redux/Inference/actions';

const initialState = {
  files: [],
  inferring: false
};

export function inferenceReducer(state = initialState, action) {
  switch (action.type) {

    case InferenceActions.SET_INFERENCE_FILES:
      return {
        ...state,
        files: action.data
      };

    case InferenceActions.SET_INFERRING:
      return {
        ...state,
        inferring: action.data
      };

    default:
      return state;
  }
}
