import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { rootReducer } from 'redux/@store/reducer';
import { verifyAuth } from 'services/Auth/auth-api';

const reduxDevTools = window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f;

function configureStore(persistedState) {
  const store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk), reduxDevTools),
  );
  store.dispatch(verifyAuth())
  return store;
}

export const store = configureStore()
