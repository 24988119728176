
import React, { useEffect } from "react";
//redux
import { fetchOn, fetchOff } from 'redux/General/actions'
import * as projectActions from 'redux/Project/actions'
import * as dataModelActions from 'redux/DataModel/actions'
import { useDispatch, useSelector } from 'react-redux'
//firebase
import { db } from 'global/firebase'
//router
import { useHistory } from 'react-router-dom'


export const useProjectData = projectId => {
  const dispatch = useDispatch()
  const history = useHistory()
  const userId = useSelector(store => store.auth.authUID)

  useEffect(() => {
    dispatch(fetchOn('project'))

    const unsubscribe = db.collection("users").doc(userId).collection("projects").doc(projectId)
    .onSnapshot(function(doc) {
      if (!doc.exists || doc.data().deleted) {
        dispatch(fetchOff('project'))
        history.push('/user')
      } else {
        dispatch(projectActions.setProjectData({...doc.data(), projectId: projectId}))
        dispatch(fetchOff('project'))
      }
    }, function (error) {window.alert(error)})

    return () => {
      unsubscribe()
    }
  }, [dispatch, projectId, userId, history])

  return true;
};

export const useCheckpointData = projectId => {
  const dispatch = useDispatch()
  const userId = useSelector(store => store.auth.authUID)

  useEffect(() => {
    dispatch(fetchOn('checkpoints'))
    const unsubscribe = db.collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection('checkpoints')
    .orderBy('checkpoint')
    .onSnapshot(function(items) {
      const checkpoints = items.docs.map(doc => ({x: doc.data().checkpoint, y: doc.data().accuracy * 100, ...doc.data()}))
      const losses = items.docs.map(doc => ({x: doc.data().checkpoint, y: doc.data().loss}))

      dispatch(projectActions.setCheckpoints(checkpoints))
      dispatch(projectActions.setLosses(losses))
      if (checkpoints.length > 1) {
        dispatch(projectActions.setMostRecentData(checkpoints[checkpoints.length-1]))
        dispatch(projectActions.setPrevData(checkpoints[checkpoints.length-2]))
        dispatch(fetchOff('checkpoints'))
      } else {
        dispatch(projectActions.setMostRecentData(null))
        dispatch(projectActions.setPrevData(null))
        dispatch(fetchOff('checkpoints'))
      }
    });

    return () => {
      unsubscribe()
    }
  }, [dispatch, projectId, userId])

  return true;
}

export const useDataModel = projectId => {
  const dispatch = useDispatch()
  const userId = useSelector(store => store.auth.authUID)

  useEffect(() => {
    dispatch(fetchOn('dataModel'))
    const unsubscribe = db.collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection('data_model')
    .onSnapshot(function(items) {
      const dataModel = items.docs.map(doc => doc.data())
      dispatch(dataModelActions.setDataModel(dataModel))
      dispatch(fetchOff('dataModel'))
    });

    return () => {
      unsubscribe()
    }
  }, [dispatch, projectId, userId])

  return true;
}

export const useCategoryData = (columnName) => {
  const dispatch = useDispatch()
  const userId = useSelector(store => store.auth.authUID)
  const projectId = useSelector(store => store.project.projectId)

  const [categories, setCategories] = React.useState([])

  useEffect(() => {
    if (projectId) {
      dispatch(fetchOn('categoryData'))
      const unsubscribe = db.collection("users")
      .doc(userId)
      .collection("projects")
      .doc(projectId)
      .collection('data_model')
      .doc(columnName)
      .collection('categories')
      .onSnapshot(function(items) {
        const categories = items.docs.map(doc => doc.data())
        console.log(categories)
        setCategories(categories)
        dispatch(fetchOff('categoryData'))
      });

      return () => {
        unsubscribe()
      }
    } else {
      return () => {
        console.log('nothing')
      }
    }

  }, [dispatch, projectId, userId, columnName])

  return categories;
}

export const useDataFiles = projectId => {
  const dispatch = useDispatch()
  const userId = useSelector(store => store.auth.authUID)
  //dispatch(projectActions.setFiles([]))


  useEffect(() => {
    dispatch(fetchOn('files'))
    const unsubscribe = db.collection("users")
    .doc(userId)
    .collection("projects")
    .doc(projectId)
    .collection('files')
    .orderBy("server_date_added", "desc")
    .onSnapshot(function(items) {
      const data = items.docs.map(doc => {
        return { id: doc.id, ...doc.data() };
      });
      dispatch(projectActions.setFiles(data))
      dispatch(fetchOff('files'))
    });

    return () => {
      unsubscribe()
    }
  }, [dispatch, projectId, userId])

  return true;
}