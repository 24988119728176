import React from 'react'
//material ui
import { 
  Button, 
  Container, 
  Grid, 
  makeStyles, 
  Typography,
  Toolbar, Tooltip
} from '@material-ui/core'
import Delete from '@material-ui/icons/Delete'
import Restore from '@material-ui/icons/Restore'
//components
import Model from './components/Model'
import DeployMonitor from './components/DeployMonitor'
//router
import DataManagement from './components/DataManagement'
//redux
import { useDispatch } from 'react-redux'
import { deleteModal, resetModal } from 'redux/Project/actions'

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main
  },
  dataCard: {
    height: '350px',
    
  },
  cardContent: {
    overflow: 'auto'
  },
  list: {
    width: '100%',
  },
  header: {
    width: '100% + 0px',
    backgroundColor: theme.palette.background.default,
    margin: '-48px -16px 48px -16px',
    height: '48px',
    borderBottom: '1px solid rgba(255,255,255,0.12)',
    overflowX: 'auto',
    color: 'rgba(255, 255, 255, 0.7)'
  },
  cardHeader: {
    //backgroundColor: theme.palette.background.default,
    //margin: '-48px -16px 48px -16px',
    height: '48px',
    borderBottom: '1px solid rgba(255,255,255,0.12)',
    //overflowX: 'auto',
    color: 'rgba(255, 255, 255)'
  },
  cardTitle: {
    height: '100%',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      minWidth: 32,
      "& .MuiButton-startIcon": {
        margin: 0
      }
    }
  },
  buttonText: {
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  content: {
    position: 'absolute', left: '50%', top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  container: {
    textAlign: 'center', 
    width: '100%'
  },
  icon: {
    fontSize: 40
  }
}))

function Home(props) {
  const classes = useStyles()
  const dispatch = useDispatch()

  return (
    <React.Fragment>
      <Toolbar className={classes.header}>
        <Grid container spacing={1} className={classes.container}>
          <Grid item justify='center' style={{paddingTop:'5px'}}>
            <Typography variant='h6' style={{paddingRight: '10px'}}>Actions:</Typography>
          </Grid>
          <Grid item>
            <Tooltip title='Delete'>
              <Button
                variant='text'
                color='inherit'
                className={classes.button}
                startIcon={<Delete />}
                onClick={e=>dispatch(deleteModal(true))}
              >
                <span className={classes.buttonText}>Delete</span>
              </Button>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title='Reset Model'>          
              <Button
                variant='text'
                color='inherit'
                className={classes.button}
                startIcon={<Restore />}
                onClick={e=>dispatch(resetModal(true))}
              >
                <span className={classes.buttonText}>Reset Model</span>
              </Button>
            </Tooltip>
          </Grid>
        </Grid>
      </Toolbar>

      <Container data-aos="fade-up" maxWidth='lg'>
        <DataManagement classes={classes} />
        <Model classes={classes} />
        <DeployMonitor classes={classes} />
      </Container>
    </React.Fragment>
  )
}

export default Home
