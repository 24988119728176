//redux
import { store } from 'redux/@store'
import * as inferenceActions from 'redux/Inference/actions'
//axios
import axios from 'axios';
//other api
import { setError } from 'redux/General/actions';

export function inferFile(file, checkpoint) {
  return dispatch => {
    dispatch(inferenceActions.setInferring(true))
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId
    var formData = new FormData();
    formData.append('file', file)
    formData.append("project_id", projectId);
    formData.append("user_id", userId);
    formData.append("checkpoint", checkpoint);

    axios.post(`${process.env.REACT_APP_API_URL}/file_inference`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }).then(res => {
      console.log(res)
      dispatch(inferenceActions.setInferring(false))
      //TODO
      //dispatch file thunk
    }).catch(error => {
      console.log('AXIOS ERROR', error)
      dispatch(inferenceActions.setInferring(false))
      dispatch(setError('GENERAL', 'Error with inferring model.'))
    })
  }
}

