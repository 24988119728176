import { isVerifying, authUser, signOutUser, authError } from 'redux/Auth/actions'
import { auth, db } from 'global/firebase'
import Firebase from 'firebase'
import history from 'global/history'
import { setError, showAuthModal } from 'redux/General/actions'

export function verifyAuth ()  {
  return dispatch => {
    dispatch(isVerifying(true))
    Firebase.auth().onAuthStateChanged(user => {
      if (user) {
        db.collection("users")
        .doc(user.uid)
        .get()
        .then(results => {
          const userinfo = results.data();
          dispatch(authUser({
            authUser: userinfo.username, 
            authUID: user.uid, 
            email: userinfo.email
          }));
          dispatch(isVerifying(false))
        })
        .catch(
            error => {console.log(error)
            dispatch(isVerifying(false))
            dispatch(setError('GENERAL', 'Unable to login at this time.'))

        });
      } else {
        dispatch(signOutFirebase());
      }
    });
  }
}

export function signOutFirebase () {
  return dispatch => {
    Firebase.auth()
    .signOut()
    .then(() => {
      dispatch(signOutUser());
    });
  }
}

export function signInUser(credentials) {
  return function(dispatch) {

    dispatch(isVerifying(true))
    auth
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(response => {
        db.collection("users")
        .doc(response.user.uid)
        .get()
        .then(newresults => {
          const userinfo = newresults.data();
          dispatch(authUser({
            authUser: userinfo.username, 
            authUID: response.user.uid, 
            email: userinfo.email,
          }));
          dispatch(showAuthModal(false))
          dispatch(isVerifying(false))
          history.push({pathname: '/user'})
        });
      })
      .catch(error => {
        dispatch(authError(error));
        dispatch(isVerifying(false))
      });
  };
}

export function signUpUser(credentials) {
  return function(dispatch) {
    dispatch(isVerifying(true))
    auth
      .createUserWithEmailAndPassword(credentials.email, credentials.password)
      .then(response => {
        db.collection("users")
          .doc(response.user.uid)
          .set({
            email: credentials.email,
            username: credentials.username.toLowerCase(),
            createdDate: Firebase.firestore.FieldValue.serverTimestamp()
          });
        dispatch(signInUser(credentials));
        //dispatch(isVerifying(false))
      })
      .catch(error => {
        //dispatch(general.handleClickOpen("signupModal"))
        dispatch(authError(error));
        dispatch(isVerifying(false))
      });
  };
}