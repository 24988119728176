import React, { useState } from 'react'
//material
import { InputAdornment, DialogContent, List, ListItem, TextField, DialogActions, Button, makeStyles, Typography } from '@material-ui/core'
import Email from '@material-ui/icons/Email'
import Lock from '@material-ui/icons/Lock'
//redux
import { useDispatch } from 'react-redux'
import { signInUser } from 'services/Auth/auth-api'


const useStyles = makeStyles((theme) => ({
  actions: {
      WebkitBoxPack: "center !important",
      MsFlexPack: "center !important",
      justifyContent: "center !important",
      padding: '15px'
  },
  error: {
    color: theme.palette.secondary.main
  },
  errorContainer: {
    padding: '20px',
    maxWidth: '250px',
  },
}))

export default function Login (props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { error } = props
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleUserLogin();
    }
  };

  const handleUserLogin = () => {
    dispatch(signInUser({email: email, password: password}))
  }

  return (
    <React.Fragment>
    <DialogContent>
      <div id='loginform' onKeyDown={handleKeyDown}>
      <List>
        <ListItem>
          <TextField
            fullWidth 
            variant='outlined' 
            color='primary' 
            //label='Email'
            placeholder='Email *'
            value={email}
            onChange={e => setEmail(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Email />
                </InputAdornment>
              )
            }}
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth 
            variant='outlined' 
            color='primary' 
            //label='Email'
            placeholder='Password *'
            type='password'
            value={password}
            onChange={e => setPassword(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Lock />
                </InputAdornment>
              )
            }}
          />
        </ListItem>
      </List>
      </div>
    </DialogContent>
    <DialogActions className={classes.actions}>
      <Button color='primary' variant='contained' size='large' onClick={e => handleUserLogin()}>Submit</Button>
    </DialogActions>
    {error && <div className={classes.errorContainer}><Typography variant='body2' className={classes.error}>{error.message}</Typography></div>}
    </React.Fragment>
  )
}