import React from 'react'
//material ui
import { Card, CardContent, Typography, makeStyles, useTheme } from '@material-ui/core'
//redux
import { useSelector } from 'react-redux'
import CardLoader from 'components/molecules/CardLoader/CardLoader';
//Canvas
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;


const useStyles = makeStyles(theme => ({
  metricCard: {
    color: 'white',
    marginTop: '20px',
    //border: '0px',
    padding: '0px'
  },
  sectionTitle: {
    color:'white',
    padding: '10px',
    fontWeight: 800
  }
}))

function ModelAccuracy(props) {
  const{ chartOnly, chartHeight } = props
  const { checkpoints, losses } = useSelector(store => store.project)
  const fetching = useSelector(store => store.general.fetching)
  const classes = useStyles()
  const theme = useTheme()

  const options = {
    theme: "light1", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    chartHeight: '100px',
    axisX: {
      labelFontFamily: `"Play", sans-serif`,
      titleFontFamily: `"Play", sans-serif`,
      tickColor: 'white',
      lineColor: 'white',
      gridDashType: "dot",
			gridThickness: 2,
      labelFontColor: 'white',
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    axisY: {
      //minimum: 0,
      //maximum: 101,
      labelFontFamily: `"Play", sans-serif`,
      titleFontFamily: `"Play", sans-serif`,
      titleFontSize: 16,
      title: 'Accuracy',
      titleFontColor: "white",
      tickColor: 'white',
      lineColor: 'white',
      labelFontColor: 'white',
      includeZero: false,
      gridThickness: 0,
      valueFormatString: "0.00'%'",
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    axisY2: {
      //minimum: 0,
      titleFontFamily: `"Play", sans-serif`,
      labelFontFamily: `"Play", sans-serif`,
      titleFontSize: 16,
      title: "Loss",
      titleFontColor: "white",
      lineColor: "white",
      labelFontColor: "white",
      tickColor: "white",
      includeZero: false
    },
    toolTip: {
      shared: true
    },
    legend: {
      fontColor: 'white',
      fontFamily: `"Play", sans-serif`,
    },
    data: [
      {
        type: "line",
        name: 'Accuracy',
        color: "#21CE99",
        lineColor: "#21CE99",
        yValueFormatString: "0.00'%'",
        showInLegend: true,
        lineThickness: 2,
        dataPoints: checkpoints
      },
      {
        type: "line",
        name: 'Loss',
        showInLegend: true,
        axisYType: "secondary",
        lineThickness: 2,
        dataPoints: losses
      },
    ]
  }

  if (chartOnly) {
    return (
      <CanvasJSChart containerProps={{height: chartHeight?chartHeight:400}} options={options} />
    )
  }

  return (
    <div>
      <Card elevation={5} className={classes.metricCard}>
        <CardContent elevation={0} style={{textAlign: 'center'}}>
          <CardLoader height={chartHeight?chartHeight:'400px'} isLoading={fetching.checkpoints} placeHolder={checkpoints.length === 0} placeHolderLabel='No Training Data'>
            <Typography gutterBottom variant='h6' className={classes.sectionTitle}>Training Accuracy</Typography>
            <div>
              <CanvasJSChart containerProps={{height: '400px'}} options={options} />
            </div>
          </CardLoader>
        </CardContent>
      </Card>
    </div>
  )
}

export default ModelAccuracy
