import React, { useEffect } from 'react'
//material ui
import { Grid, Card, CardHeader, CardContent, Typography, FormControl, Select, MenuItem, OutlinedInput, CircularProgress, Button } from '@material-ui/core'
import Warning from '@material-ui/icons/Warning'
import Keyboard from '@material-ui/icons/Keyboard'
//redux
import { useSelector, useDispatch } from 'react-redux'
//form
import { useForm } from "react-hook-form";
//services
import { infer } from 'services/Project/model-api'
//components
import NumberInput from '../components/NumberInput'
import StringInput from '../components/StringInput'
import CategoryInput from '../components/CategoryInput'
import ProbChart from '../components/ProbChart';

function SingleInput(props) {
  const dispatch = useDispatch()
  const projectData = useSelector(store => store.project)
  const data_model = useSelector(store => store.dataModel.data_model)
  const { register, handleSubmit, errors, getValues, setValue } = useForm();
  console.log(data_model)

  register('checkpoint')
  setValue('checkpoint', 10)

  const handleClick = e => {
    const values = getValues(data_model.map(item => item.name))
    const newValues = JSON.stringify([values], function (key, value) {return (value === undefined) ? "" : value});
    dispatch(infer(newValues, getValues('checkpoint')))
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Card elevation={5}>
          <CardHeader title='Model Input' titleTypographyProps={{variant: 'body1', color: 'textSecondary'}} /> 
          <CardContent>
            <form onSubmit={handleSubmit}>
            <Grid container spacing={2} justify='flex-start'>
            <Grid item xs={12}>
                <FormControl
                name="related"
                //label="Target Column"
                validations="isExisty"
                validationError="Must choose a column"
                variant='outlined'
                fullWidth
                fontColor='white'
                >
                  <Select 
                    input={<OutlinedInput />}
                    defaultValue={0}
                    disabled={projectData.checkpoint === 0}
                    onChange={e => {
                      setValue('checkpoint', projectData.checkpoints[e.target.value].checkpoint); 
                    }}
                  >
                    <MenuItem value={null}>
                        <em>None</em>
                    </MenuItem>
                    {projectData.checkpoints?projectData.checkpoints.map((item, i) => (
                      <MenuItem value={i}>Checkpoint {item.checkpoint} | {(item.accuracy*100).toFixed(2)}%</MenuItem>
                    )):null}
                  </Select>
                </FormControl>
              </Grid>
              {data_model.map(item => {
                if (projectData.ignored_columns.includes(item.name) || projectData.target_column === item.name) {
                  return null
                }
                switch (item.type) {
                  case 'numeric':
                    return (
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <NumberInput item={item} errors={errors} register={register} />
                      </Grid>
                    )
                  case 'string/mixed':
                    return (
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <StringInput item={item} errors={errors} register={register} />
                      </Grid>
                    )
                  case 'categorical':
                    return (
                      <Grid item xs={12} sm={4} md={3} lg={3}>
                        <CategoryInput setValue={setValue} item={item} errors={errors} register={register} />
                      </Grid>
                    )
                  default:
                    return null
                  }
                }
              )}
              <Grid item container justify='center' xs={12}>
                <Button 
                  size='large' 
                  color='primary' 
                  variant='contained'
                  onClick={e => handleClick(e)}
                  disabled={projectData.inferring}
                >
                Predict
                </Button>
              </Grid>
            </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card elevation={5}>
          <CardHeader title='Results' titleTypographyProps={{variant: 'body1', color: 'textSecondary'}} /> 
          <CardContent>
              <div style={{width: '100%', height: '360px', textAlign: 'center'}}>
                {(projectData.inferData.probabilities) && <ProbChart data={projectData.inferData.translated_probabilities[0]} /> }
                <div style={{paddingTop: '125px'}}>
                  {(projectData.inferring) && <><CircularProgress size={65} /><Typography color='primary'>Inferring...</Typography></>}
                  {(Object.keys(errors).length > 0) && <><Warning color='secondary' style={{fontSize: 100}} /><Typography color='secondary'>Fix Errors</Typography></>}
                  {(!projectData.inferring && Object.keys(projectData.inferData).length === 0 && Object.keys(errors).length === 0) && <><Keyboard color='secondary' style={{fontSize: 100}} /><Typography color='secondary'>Need More Data...</Typography></>}
                </div>
              </div>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  )
}

export default SingleInput
