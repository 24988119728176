//redux
import { store } from 'redux/@store'
import { fetchOn, fetchOff, setError } from 'redux/General/actions'
import * as projectActions from 'redux/Project/actions'
//firebase
import { db } from 'global/firebase'
//router
import history from 'global/history'
//axios
import axios from 'axios';

export function addProject(payload) {
  return dispatch => {
    dispatch(fetchOn('project'))
    var userId = store.getState().auth.authUID

    db.collection("users")
    .doc(userId)
    .collection("projects")
    .add(
      payload
    ).then(response => {
      history.push(`/projectv2/${response.id}/type/${payload.typeCode}/pg/upload`)
      dispatch(fetchOff('project'))
    })
    .catch(error => {
      console.log(error)
      dispatch(fetchOff('project'))
    })
  }
}

export function uploadDataFile(files, projectId) {
  return dispatch => {

    let sizeCheck = files.some(file => (file.size / (1024 * 1024)) >= 32)
    if (sizeCheck) {
      dispatch(setError('GENERAL', 'Files must be under 32MB.'))
    } else {
      dispatch(projectActions.uploadModal(true))
      dispatch(projectActions.setUploadFiles(files.map(file => ({name: file.name, success: 'pending'}))))
      dispatch(projectActions.isUploadingFiles(true))
      var userId = store.getState().auth.authUID
      var results = []

      function asyncFunction (file, resolve) {
        var formData = new FormData();
        formData.append('file', file)
        formData.append("project_id", projectId);
        formData.append("user_id", userId);
  
        axios.post(`${process.env.REACT_APP_API_URL}/data_file_upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(res => {
          results.push({'name': file.name, success: true})
          resolve(true)
        }).catch(error => {
          console.log('AXIOS ERROR', error)
          results.push({'name': file.name, success: false})
          resolve(false)
        })
      }
  
      let requests = files.map((file) => {
        return new Promise((resolve) => {
          asyncFunction(file, resolve);
        });
      })
      
      Promise.all(requests).then(() => {
        dispatch(projectActions.setUploadFiles(results))
      });
    }
  }
}

export function handleUseful(name) {
  return dispatch => {
    //dispatch(fetchOn('project'))
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId
    var ignored_columns = store.getState().project.ignored_columns
    const ignored_columns_update = !ignored_columns ? [name] : (ignored_columns.includes(name) ? ignored_columns.filter(item => item !== name) : [...ignored_columns, name])
    
    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set(
      {ignored_columns: ignored_columns_update}, {merge: true}
    ).then(response => {
      //dispatch(fetchOff('project'))
    })
    .catch(error => {
      //dispatch(fetchOff('project'))
      dispatch(setError('GENERAL', 'Failed to set unuseful column.'))
    })
  }
}

export function handleIgnoredColumns(columns) {
  return dispatch => {
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId
    
    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set(
      {ignored_columns: columns}, {merge: true}
    ).then(response => {
    })
    .catch(error => {
      dispatch(setError('GENERAL', 'Failed to set unuseful column.'))
    })
  }
}

export function handleTarget(name) {
  return dispatch => {
    dispatch(fetchOn('project'))
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId
    
    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set(
      {target_column: name}, {merge: true}
    ).then(response => {
      console.log('changed')
      dispatch(fetchOff('project'))
    })
    .catch(error => {
      console.log('error')
      dispatch(fetchOff('project'))
      dispatch(setError('GENERAL', 'Failed to set target.'))
    })
  }
}

export function startTraining (epoch) {
  return dispatch => {
    var userId = store.getState().auth.authUID
    var { projectId, checkpoint } = store.getState().project

    //update firebase with new boxes
    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set({
      trainStatus: 'Starting Request...',
      epochs: epoch,
      checkpointGoal: checkpoint + epoch,
      isTraining: true
    }, {merge: true})
    .then(response => {
        console.log('complete')
    }).catch(error => {
      console.log('Value', error)
      db.collection("users")
      .doc(userId)
      .collection('projects')
      .doc(projectId)
      .set({
        trainStatus: 'Request Failed'
      }, {merge: true}).then(response => {
          console.log('marked as failed')
      }).catch(error => {
        console.log('AXIOS ERROR', error)
        dispatch(setError('GENERAL', 'Failed to start training process.'))
      })
    })
  }
}

export function deleteProject(projectId) {
  console.log('DELETING', projectId)
  return dispatch => {
    dispatch(projectActions.isDeleting(true))
    var userId = store.getState().auth.authUID

    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .set(
      {deleted: true}, {merge: true}
    ).then(response => {
      dispatch(projectActions.deleteModal(false))
      dispatch(projectActions.isDeleting(false))
    })
    .catch(error => {
      dispatch(projectActions.deleteModal(false))
      dispatch(projectActions.isDeleting(false))
      dispatch(setError('GENERAL', 'Failed to delete project.'))
    })
  }
}

export function deleteFile(docId) {
  return dispatch => {
    var userId = store.getState().auth.authUID
    var projectId = store.getState().project.projectId

    db.collection("users")
    .doc(userId)
    .collection('projects')
    .doc(projectId)
    .collection('files')
    .doc(docId)
    .delete()
    .then(function() {
      console.log("Document successfully deleted!");
    }).catch(function(error) {
      dispatch(setError('GENERAL', 'Failed to delete file.'))
    });
  }
}