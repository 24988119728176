import React from 'react'
//material ui
import { Grid, Typography, Card, Toolbar, CardContent } from '@material-ui/core'
import CardLoader from 'components/molecules/CardLoader/CardLoader'
import Check from '@material-ui/icons/Check'
//redux
import { useSelector } from 'react-redux'

function Model(props) {
  const { classes } = props
  const fetching = useSelector(store => store.general.fetching)
  const prodDeployment = useSelector(store => store.project.prodDeployment)
  const testDeployment = useSelector(store => store.project.testDeployment)
  
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography className={classes.title} variant='h6' gutterBottom>Deploy & Monitor</Typography>
      </Grid>
      <Grid item xs={12} sm={6} lg={4}>
        <Card elevation={5} className={classes.dataCard} style={{height: '150px'}} >
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1' gutterBottom>Production Deployment</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            <CardLoader
              isLoading={fetching.project}
              placeHolder={!prodDeployment}
              placeHolderLabel='No Model Deployed'
              placeHolderHeight='75px'
            > 
              <div className={classes.container}>
                <Check className={classes.icon} color='primary' />
                <Typography color='primary' variant='h6'>Checkpoint {prodDeployment}</Typography>
              </div>
            </CardLoader>
          </CardContent>
        </Card>
        <Card elevation={5} className={classes.dataCard} style={{height: '150px', marginTop: '10px'}} >
          <Toolbar className={classes.cardHeader}>
            <Grid container spacing={2}>
              <Grid item xs>
                <Typography className={classes.cardTitle} variant='subtitle1' gutterBottom>Test Deployment</Typography>
              </Grid>
            </Grid>
          </Toolbar>
          <CardContent>
            <CardLoader
              isLoading={fetching.project}
              placeHolder={!testDeployment}
              placeHolderLabel='No Model Deployed'
              placeHolderHeight='75px'
            >
              <div className={classes.container}>
                <Check className={classes.icon} color='primary' />
                <Typography color='primary' variant='h6'>Checkpoint {testDeployment}</Typography>
              </div>
            </CardLoader>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Model
