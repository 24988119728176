import React from 'react'
//material ui
import { Card, CardContent, Grid, makeStyles, CardHeader } from '@material-ui/core'
//redux
import { useSelector } from 'react-redux'
//components
import FeatureChart from './Charts/FeatureChart'
import HeatMap from './Charts/HeatMap'

const useStyles = makeStyles(theme => ({
  metricCard: {
    color: 'white',
    backgroundColor: theme.palette.background.default,
    marginTop: '20px',
    //border: '0px',
    padding: '0px'
  },
  sectionTitle: {
    color:'white',
    padding: '10px',
    fontWeight: 800
  },
  container: {
    marginTop: '15px'
  }
}))

function InterpretModel(props) {
  const classes = useStyles()
  const projectData = useSelector(store => store.project)
  const mostRecentData = useSelector(store => store.project.mostRecentData)
  return (
    <div>
      <Grid className={classes.container} container justify='center' spacing={3}>
        <Grid item xs={12} sm={6}>
          <Card elevation={5}>
            <CardHeader title='Feature Importance' titleTypographyProps={{variant: 'body1', color: 'textSecondary'}} /> 
            <CardContent>
              <FeatureChart data={mostRecentData?mostRecentData.importance:[]} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card elevation={5}>
            <CardHeader title='Confusion Matrix' titleTypographyProps={{variant: 'body1', color: 'textSecondary'}} /> 
            <CardContent>
              <div style={{padding: '0px 15px 15px 15px'}}>
                <HeatMap data={projectData} mostRecent={mostRecentData} />
              </div>
            </CardContent>
          </Card>

        </Grid>
      </Grid>
    </div>
  )
}

export default InterpretModel
