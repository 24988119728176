export const routes = {
  HOME_PAGE: '/',
  NEW_PROJECT: '/newproject',
  NEW_API_KEY: '/newapikey',
  DOCS: '/docs',
  USER: '/user',
  INITIALIZE: '/project/:projectId/type/:typeCode/pg/initialize',
  PROJECT: '/project/:projectId/type/:typeCode/pg/:page',
  PROJECTV2: '/projectv2/:projectId/type/:typeCode/pg/:page',
  COLUMN: '/project/:projectId/type/:typeCode/pg/column/name/:column'
}