import React from 'react';
//material ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Button, TextField } from '@material-ui/core';
//form
import { useForm } from "react-hook-form";
//services
import { addMessage } from 'services/Support/support-api'
//redux
import { useDispatch } from 'react-redux'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const ContactForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const { handleSubmit, register, errors, reset } = useForm();

  const onSubmit = (data, e) => {
    reset(dispatch(addMessage(data)))
  }

  return (
    <div className={classes.root}>
      <form
        name="contact-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <input type="hidden" name="form-name" value="contact-form" />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              <strong>Contact Us</strong>
            </Typography>
            <Typography variant="h6" color="textSecondary" align="center">
              We carefully read and answer to all our emails.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="Full Name"
              label="Full Name *"
              variant="outlined"
              size="medium"
              error={errors.fullname}
              helperText={errors.fullname && errors.fullname.message} 
              inputRef={register({
                required: true,
              })}
              name="fullname"
              fullWidth
              type="text"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder="E-mail"
              label="E-mail *"
              variant="outlined"
              size="medium"
              name="email"
              fullWidth
              error={errors.email}
              helperText={errors.email && errors.email.message} 
              inputRef={register({
                required: true,
                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
              })}
              //type="email"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              error={errors.message}
              helperText={errors.message && 'Required'} 
              inputRef={register({required: true})} 
              placeholder="Message"
              label="Message *"
              variant="outlined"
              name="message"
              fullWidth
              multiline
              rows={4}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2" gutterBottom>
              Fields that are marked with * sign are required.
            </Typography>
            <Button
              size="large"
              variant="contained"
              type="submit"
              color="primary"
              //disabled={!formState.isValid}
            >
              Send
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ContactForm;
