import React from 'react'
//material ui
import { makeStyles, Grid, Typography, useMediaQuery, useTheme, Avatar } from '@material-ui/core'
import AccountBalance from '@material-ui/icons/AccountBalance'
import MonetizationOn from '@material-ui/icons/MonetizationOn';
import HowToVoteOutlined from '@material-ui/icons/HowToVoteOutlined'
import Timer from '@material-ui/icons/Timer';
//components
import DescriptionListIcon from 'components/organisms/DescriptionListIcon';
import Typed from 'react-typed'

const useStyles = makeStyles(theme => ({
  section: {
    backgroundColor: theme.palette.background.default,
    //height: '750px',
  },
  paper: {
    height: '250px'
  },
  container: {
    //paddingTop: '50px'
  },
  ml: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  typed: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
  },
  subtitle: {
    fontSize: 26,
    [theme.breakpoints.down('sm')]: {
      minHeight: 75,
    },
  },
  avatar: {
    width: 70,
    height: 70,
    backgroundColor: theme.palette.background.card,
    borderRadius: theme.spacing(2),
  }
}))

const data = [
  {
    title: 'Create Business Value',
    subtitle: 'Use machine learning tools to launch your business users miles ahead of their competition.',
    icon: <AccountBalance color='secondary' style={{fontSize: 40}} />
  },
  {
    title: 'Reduce AI/ML Cost',
    subtitle: 'PowerAI provides an alternative for expensive data scientists and ML infrastructure.',
    icon: <MonetizationOn color='secondary' style={{fontSize: 40}} />
  },
  {
    title: 'Democratize Access',
    subtitle: 'Enable your business users to leverage the power of machine learning without learning to code.',
    icon: <HowToVoteOutlined color='secondary' style={{fontSize: 40}} />
  },
  {
    title: 'Shorten ML Lifecycle',
    subtitle: 'Our framework helps your business implement the optimal path for operational machine learning.',
    icon: <Timer color='secondary' style={{fontSize: 40}} />
  }
]

function ProductValue(props) {
  const { aboutRef } = props
  const classes = useStyles()
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const isSm = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  });
  return (
    <div data-aos='fade-up' ref={aboutRef} id='about' className={classes.section}>
        <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12}>
        <Typography className={classes.subtitle} align={isSm ? 'left' : 'center'} data-aos={'fade-right'} variant='h4' component='h6'>Never worry about {
              <Typed
                className={classes.typed} 
                strings={[
                  'Infrastructure', 
                  'Data Preprocessing',
                  'Feature Engineering',
                  'Training Pipelines',
                  'Deciphering Models',
                  'Deployment'
                  ]} 
                typeSpeed={50} 
                loop={true} 
                backSpeed={40}
              />}</Typography>
              
        </Grid>
          {data.map((item, index) => (
            <Grid key={index} item xs={12} sm={3} data-aos={'fade-up'}>
              <DescriptionListIcon
                title={item.title}
                subtitle={item.subtitle}
                icon={
                  <Avatar className={classes.avatar}>
                    {item.icon}
                  </Avatar>
                }
                align={isSm ? 'left' : 'center'}
              />
            </Grid>
          ))}
        </Grid>
    </div>
  )
}

export default ProductValue
