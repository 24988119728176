import React from 'react'
//material ui
import { Grid, Card, CardContent, CardHeader, Typography, FormControl, Select, OutlinedInput, MenuItem } from '@material-ui/core'
//redux
import { useDispatch, useSelector } from 'react-redux'
//components
import FileTable from './components/FileTable'
import EvalDropZone from 'components/organisms/DropZone/EvalDropZone'
import CardLoader from 'components/molecules/CardLoader/CardLoader'
import { inferFile } from 'services/Inference/inference-api'

function SingleInput(props) {
  const projectData = useSelector(store => store.project)
  const inferenceData = useSelector(store => store.inference)
  const dispatch = useDispatch()
  const [value, setValue] = React.useState(0)

  const handleFileAdd = (files) => {
    dispatch(inferFile(files[0], projectData.checkpoints[value].checkpoint))
  }

  return (
    <React.Fragment>
      <Grid item xs={12}>
        <Card elevation={5}>
          <CardHeader title='Batch Inference' titleTypographyProps={{variant: 'body1', color: 'textSecondary'}} /> 
          <CardContent>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <FormControl name="related" variant='outlined' fullWidth >
                  <Select
                    disabled={inferenceData.inferring}
                    input={<OutlinedInput />}
                    defaultValue={0}
                    value={value}
                    onChange={e => setValue(e.target.value)}
                  >
                    {projectData.checkpoints?projectData.checkpoints.map((item, i) => (
                      <MenuItem value={i}>Checkpoint {item.checkpoint} | {(item.accuracy*100).toFixed(2)}%</MenuItem>
                    )):null}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <CardLoader isLoading={inferenceData.inferring} loaderHeight='150px'>
                  <EvalDropZone handleFileAdd={handleFileAdd} />
                </CardLoader>
              </Grid>

              <Grid item xs={12}>
                <FileTable />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </React.Fragment>
  )
}

export default SingleInput
