import React from 'react'
//material
import { Container, Grid } from '@material-ui/core'
//components
import DeployTable from './components/DeployTable'

export default function Expore (props) {

  return (
    <Container maxWidth='lg' data-aos="fade-up">
      <Grid container justify='left' spacing={3}>
        <Grid item xs={12}>
          <DeployTable />
        </Grid>
      </Grid>
    </Container>
  )
}