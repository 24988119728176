import { useTheme } from '@material-ui/core';
import React from 'react'
import {useSelector} from 'react-redux'

var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default function DonutChart (props) {
  const projectData = useSelector(store => store.project)
  const theme = useTheme()

  const options = {
    theme: "dark2", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    subtitles: [{
      fontFamily: `"Play", sans-serif`,
      text: projectData.epochs - (projectData.checkpointGoal - projectData.checkpoint) + '/' + projectData.epochs,
      verticalAlign: "center",
      fontSize: 18,
      dockInsidePlotArea: true
    }],
    data: [{
      indexLabelFontFamily: `"Play", sans-serif`,
      type: "doughnut",
      dataPoints: [
        { name: "Current", y: projectData.epochs - (projectData.checkpointGoal - projectData.checkpoint), color: '#21CE99' },
        { name: "Goal", y: projectData.checkpointGoal - projectData.checkpoint, color: '#FF7597'  },
      ]
    }]
  }

  const containerProps =  {
    height: '150px',
    paddingTop: '0px'
  }

  return (
    <CanvasJSChart containerProps={containerProps} options={options} />
  )
}