import React from 'react'
import { Switch, Route, Redirect } from "react-router-dom";
//containers
import PrivateRoute from 'containers/PrivateRoute'
//pages
import Landing from 'pages/LandingAlt'
import UserDashboard from 'pages/UserDashboard'
import Project from 'pages/Project'
import Initialize from 'pages/Projectv2'
import NewProject from 'pages/NewProject';
//routes
import { routes } from 'global/routes'
import Docs from 'pages/Docs';

const SwitchRoutes = (props) => (
  <Switch>
    <Route exact path={routes.HOME_PAGE} render={() => <Landing />} />
    <Route exact path={routes.DOCS} render={() => <Docs />} />
    <PrivateRoute exact path={routes.USER} component={UserDashboard} />
    <PrivateRoute exact path={routes.NEW_PROJECT} component={NewProject} />
    <PrivateRoute path={routes.INITIALIZE} component={Initialize} />
    <PrivateRoute path={routes.PROJECT} component={Project} />
    <PrivateRoute path={routes.PROJECTV2} component={Initialize} />
    <Redirect from="*" to={routes.HOME_PAGE}/>
  </Switch>
)

export default SwitchRoutes;