import React from 'react'
import { Grid, Typography, makeStyles } from '@material-ui/core'
import SetupDropZone from 'components/organisms/DropZone/SetupDropZone'
import { useDispatch } from 'react-redux'
import { uploadDataFile } from 'services/Project/project-api'
import FileTable from 'components/organisms/FileTable/FileTable'

const useStyles = makeStyles(theme => ({
  dropWrapper: {
    //height: '250px',
  },
  container: {

  }
}))

export default function Upload(props) {
  const { match } = props
  const classes = useStyles()
  const dispatch = useDispatch()

  console.log('MATCH', match)
  const handleFileAdd = (files) => {
    dispatch(uploadDataFile(files, match.params.projectId))
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6" gutterBottom color='primary'>
        Upload Data
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={classes.dropWrapper}>
            <SetupDropZone handleFileAdd={handleFileAdd} />
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom color='primary'>
            Existing Files
          </Typography>
          <FileTable />
        </Grid>
      </Grid>
    </div>
  )
}