import React, { useState } from 'react'
//form
import { useForm } from "react-hook-form";
//@material ui
import { Grid, Typography, MenuItem, Slider, Button, makeStyles, OutlinedInput, Select, FormControl, Card } from "@material-ui/core";
import Loop from "@material-ui/icons/Loop";
//redux
import { useSelector, useDispatch } from 'react-redux'
//service
import { startTraining, handleTarget } from 'services/Project/project-api';

const useStyles = makeStyles(theme => ({
  sectionTitle: {
    padding: '10px 10px 10px 0px',
    fontWeight: 800
  },
  sectionText: {
    padding: '0px 10px 10px 0px',
    //height: '200px'
  },
  buttonWrapper: {
    padding: '15px 10px 0px 0px',
    //height: '75px'
  },
}))

function TrainingForm(props) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const projectData = useSelector(store => store.project)
  const data_model = useSelector(store => store.dataModel.data_model)
  const { mostRecentData, prevData } = projectData

  const [epoch, setEpoch] = useState(10)
  const targetId = data_model?data_model.findIndex(item => projectData.target_column === item.name):0
  const handleSliderChange = (event, newValue) => {
    setEpoch(newValue);
  };

  const { handleSubmit } = useForm();
  const onSubmit = data => {
    dispatch(startTraining(epoch))
  }
  return (
    <Card elevation={5}>
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3} justify='flex-start' style={{margin: '0px'}}>
        <Grid item xs={12} sm={4} md={4} lg={4}>
          <div style={{padding: '0px 15px 0px 15px'}}>
            <Typography variant='h6' className={classes.sectionTitle}>Target Column</Typography>
            <div className={classes.sectionText}>
              <Typography variant='body2'>
              Select a column to be the target (what you want your model to predict).
              </Typography>
              <div className={classes.buttonWrapper}>
                <FormControl
                    name="related"
                    //label="Target Column"
                    validations="isExisty"
                    validationError="Must choose a column"
                    variant='outlined'
                    //fullWidth
                    fontColor='white'
                >
                  <Select onChange={e => {e.preventDefault(); dispatch(handleTarget(data_model[e.target.value].name))}} value={targetId} style={{color: props.fontColor, minWidth: '125px'}} input={<OutlinedInput />}>
                    {data_model?data_model.map((column, i) => (
                      column.type==='categorical'?<MenuItem value={i}>{column.name}</MenuItem>:false
                    )):null}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </Grid>
      


          <Grid item xs={12} sm={4} md={4} lg={4}>
            <div style={{padding: '0px 15px 0px 15px'}}>
              <Typography variant='h6' className={classes.sectionTitle}>Training Options</Typography>
              <div className={classes.sectionText}>
                <div style={{padding: '0px'}}>
                  <Typography variant='body2' gutterBottom>
                    <b>Epochs: </b>{epoch}
                  </Typography>
                  <Slider
                    defaultValue={30}
                    value={epoch}
                    aria-labelledby="discrete-slider"
                    valueLabelDisplay="auto"
                    onChange={handleSliderChange}
                    step={20}
                    marks
                    min={10}
                    max={500}
                  />
                  </div>
                  <Typography variant='body2' className={classes.textItem}><b>Data Split:</b> Automatic</Typography>
                  <Typography variant='body2' className={classes.textItem}><b>Target Column:</b> {projectData.target_column}</Typography>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} md={4} lg={4}>
            <div style={{padding: '0px 15px 0px 15px'}}>
              <Typography variant='h6' className={classes.sectionTitle}>Start Training</Typography>
              <div className={classes.sectionText}>
                <Typography variant='body2' className={classes.textItem}><b>Current Epoch: </b>{projectData.checkpoint}</Typography>
                <Typography variant='body2' className={classes.textItem}><b>Accuracy: </b>{mostRecentData?parseInt(mostRecentData.accuracy * 100) + '%':'0%'}</Typography>
                <Typography variant='body2' className={classes.textItem}><b>Previous Accuracy: </b>{mostRecentData && prevData?parseInt(prevData.accuracy * 100) + '%':'0%'}</Typography>
                <div className={classes.buttonWrapper}>
                  <Button 
                    //style={{borderColor: "#21CE99", color: "white", minWidth: '125px', backgroundColor: '#21CE99'}} 
                    variant="contained"
                    disabled={targetId === -1}
                    size='large'
                    //onClick={e => handleClick(e)}
                    position='bottom'
                    color='primary'
                    type='submit'
                    
                    >
                    <Loop />
                    Train
                  </Button> 
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </form>
    </Card>
  )
}

export default TrainingForm
