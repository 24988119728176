import { Grid, CircularProgress, Typography } from '@material-ui/core'
import { Warning } from '@material-ui/icons'

import React from 'react'

function CardLoader(props) {
  const { isLoading, loaderHeight, loaderText, placeHolder, placeHolderLabel, placeHolderHeight } = props

  if (isLoading) {
    return (
      <Grid style={{height: loaderHeight?loaderHeight:'250px'}} container justify='center' alignItems='center'>
        <Grid item container justify='center' alignItems='center' direction='column'>
          <CircularProgress disableShrink size={50} color='primary' />
          {loaderText && <Typography align='center' variant='h6' color='primary'>{loaderText}</Typography>}
        </Grid>
      </Grid>
    )
  } else if (placeHolder) {
    return (
      <Grid container style={{height: placeHolderHeight?placeHolderHeight:'250px'}} alignItems='center'>
        <Grid item xs={12}>
          <div style={{width: '100%', textAlign: 'center'}}>
            <Warning color='secondary' style={{fontSize: 40}} />
            <Typography variant='h6' color='secondary'>{placeHolderLabel}</Typography>
          </div>
        </Grid>
      </Grid>
    )
  }
  return (
      <>{props.children}</>
  )
}

export default CardLoader
