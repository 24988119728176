
export const AuthActions = {
  AUTH_USER: '@auth/login/success',
  IS_VERIFYING: '@auth/login/verifying',
  SIGN_OUT_USER: '@auth/user/signout',
  AUTH_ERROR: '@auth/user/error'
};


export function authUser(userDetails) {
  return {
    type: AuthActions.AUTH_USER,
    data: userDetails
  };
}

export function isVerifying(data) {
  return {
    type: AuthActions.IS_VERIFYING,
    data: data,
  };
}

export function signOutUser(userDetails) {
  return {
    type: AuthActions.SIGN_OUT_USER,
    data: userDetails,
  };
}

export function authError(error) {
  return {
    type: AuthActions.AUTH_ERROR,
    payload: error
  };
}