
export const UserActions = {
  SET_PROJECTS: '@user/fetchProjects',
  SET_API_KEYS: '@user/fetchAPIKeys'
};

export function setProjects(data) {
  return {
    type: UserActions.SET_PROJECTS,
    data: data,
  };
}

export function setAPIKeys(data) {
  return {
    type: UserActions.SET_API_KEYS,
    data: data,
  };
}