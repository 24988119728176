import React, { useState, useEffect } from 'react'
//material ui
import { Dialog, DialogTitle, DialogContent, List, ListItem, DialogActions, TextField, Button, Typography, makeStyles } from '@material-ui/core'
import Warning from '@material-ui/icons/Warning'
//redux
import { useSelector, useDispatch } from 'react-redux'
import * as projectActions from 'redux/Project/actions'
//services
import { resetProject } from 'services/Project/model-api'

const useStyles = makeStyles(theme => ({
  flex: {
    display: 'flex'
  },
  warning: {
    paddingRight: '10px',
    paddingTop: '5px'
  }
}))

export default function ResetDialog (props) {
  const classes = useStyles()
  const { resetModal, title, projectId } = useSelector(store => store.project)
  const [projectValue, setProjectValue] = useState('')
  const dispatch = useDispatch()

  useEffect(() => {
    setProjectValue('')
  }, [projectId])

  return (
    <Dialog open={resetModal}>
      <div>
        <DialogTitle id="simple-dialog-title">
          <div className={classes.flex}>
            <div className={classes.warning}>
                <Warning color='secondary' />
            </div>
            <Typography color='secondary' variant='h5'>Reset this model?</Typography>
          </div>
        </DialogTitle>
        <DialogContent>
          <List>
            <ListItem>
              <Typography variant='body1'><b>ID:</b> {projectId}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='body1'><b>Name:</b> {title}</Typography>
            </ListItem>
            <ListItem>
              <Typography variant='body2'>Confirm you want to reset this project by typing "reset".</Typography>
            </ListItem>
            <ListItem>
              <TextField onChange={e => setProjectValue(e.target.value)} value={projectValue} placeholder={'reset'} variant='outlined' color='secondary' fullWidth />
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
            <Button onClick={e => dispatch(projectActions.resetModal(false))}>Cancel</Button>
            <Button disabled={projectValue!=='reset'} onClick={e => {dispatch(resetProject(projectId)); setProjectValue('')}} variant='contained' color='secondary'>Reset</Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}