import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  textWhite: {
    //color: 'white',
  },
  title: {
    fontWeight: 'bold',
    color: theme.palette.primary.main
  },
}));

const Description = props => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest} data-aos={'fade-up'}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h4"
            align='center'
            className={clsx(classes.title)}
          >
            What we do.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" component="p" className={classes.textWhite}>
            The PowerAI enterprise AI platform democratizes data science and automates the end-to-end process for building, deploying, and maintaining machine learning at scale. Powered by the latest open-source algorithms and available as a fully-managed AI service, PowerAI gives you the power of machine learning to drive better business outcomes.
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

Description.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
};

export default Description;
