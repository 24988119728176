import React from 'react'
//components
import PowerTable from 'components/molecules/PowerTable/PowerTable'
//redux
import { useSelector, useDispatch } from 'react-redux'
//material ui
import { Checkbox, FormControlLabel, Switch } from '@material-ui/core'
//router
import { useHistory } from 'react-router-dom'
//services
import { handleUseful, handleTarget } from 'services/Project/project-api'

const schema = [
  { id: 'target', align: 'left', disablePadding: false, label: 'Target' },
  { id: 'id', align: 'left', disablePadding: false, label: 'ID' },
  { id: 'name', align: 'left', disablePadding: false, label: 'Column Name' },
  { id: 'type', align: 'left', disablePadding: false, label: 'Data Type' },
  { id: 'unuseful', align: 'left', disablePadding: false, label: 'Exclude' },
  { id: 'unique', align: 'left', disablePadding: false, label: 'Unique Values' },
  { id: 'count', align: 'left', disablePadding: false, label: 'Count' },
  { id: 'mean', align: 'left', disablePadding: false, label: 'Mean' },
  { id: 'min', align: 'left', disablePadding: false, label: 'Min' },
  { id: 'max', align: 'left', disablePadding: false, label: 'Max' },
  { id: 'std', align: 'left', disablePadding: false, label: 'Std Dev' },
];

function ExploreTable(props) {
  const rows = useSelector(store => store.dataModel.data_model)
  const project = useSelector(store => store.project)
  const fetching = useSelector(store => store.general.fetching.project)
  const history = useHistory()
  const dispatch = useDispatch()

  function handleClick (e, data) {
    history.push(`/project/${project.projectId}/type/${project.typeCode}/pg/explore/column/${data.name}`)
  }

  const data = rows.map((item, index) => ({
    id: index + 1,
    target: item.type === 'categorical'?(
    <Checkbox
      style={{padding: 0, margin: 0}}
      size='small'
      checked={item.name===project.target_column}
      inputProps={{ 'aria-labelledby': item.name }}
      onClick={e => {e.preventDefault(); dispatch(handleTarget(item.name))}}
    />
    ):null,
    name: item.name,
    type: item.type,
    unuseful: (
      <FormControlLabel
        style={{padding: 0, margin: 0}}
        control={
          <Switch
            size='small'
            checked={project.ignored_columns?project.ignored_columns.includes(item.name)?true:false:false}
            name="checkedA" 
            onClick={e => {e.preventDefault(); dispatch(handleUseful(item.name))}}
          />
        }
      />
    ),
    unique: item.unique,
    count: item.count,
    mean: item.mean?parseFloat(item.mean).toFixed(2):'',
    min: item.min,
    max: item.max,
    std: item.std?parseFloat(item.std).toFixed(2):''
  }))

  return (
    <PowerTable rows={data} schema={schema} tableKey='id' isLoading={fetching} handleClick={handleClick} />
  )
}

export default ExploreTable
