import React from 'react'

function Docs() {
  return (
    <div>
      docs
    </div>
  )
}

export default Docs
