import React, { useState } from 'react'
//material ui
import { Container, Grid, Typography } from '@material-ui/core'
//components
import Header from './components/Header'
import SingleInput from './SingleInput/SingleInput'
import BatchInput from './BatchInput/BatchInput'
import InterpretModel from './InterpretModel'

function Predict(props) {
  const [headerValue, setHeaderValue] = useState(0)
  
  return (
    <React.Fragment>
      <Typography variant="h6" color='primary'>Understand Model</Typography>
      <InterpretModel />
      <br />
      <Typography variant="h6" color='primary'>Test/Use Model</Typography>
      <Header setValue={setHeaderValue} value={headerValue} />
      <Grid container spacing={3}>
        {headerValue===0?<SingleInput />:<BatchInput />}
      </Grid>
    </React.Fragment>
  )
}

export default Predict