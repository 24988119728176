import React from 'react'
//material
import { Container, Grid, Typography, Card, CardContent } from '@material-ui/core'
//components
import ExploreTable from './ExploreTable'
import ColumnInfo from '../ColumnInfo/ColumnInfo';
import ColumnChart from './ColumnChart'
import CountUpNumber from 'components/molecules/CountUpNumber';
//redux
import {useSelector} from 'react-redux'

export default function Expore (props) {
  const rows = useSelector(store => store.dataModel.data_model)
  const files = useSelector(store => store.project.files)

  return (
    <Container maxWidth='lg' data-aos="fade-up">
      <Grid container justify='left' spacing={3}>
          <Grid item xs={12} sm={6} md={6}>
            <Card elevation={5} style={{height: '100%'}}>
              <CardContent>
                <Typography variant='h6' gutterBottom>Data Information</Typography>
                <Grid container spacing={5} justify='flex-start' alignItems='center'>
                  <Grid item>
                    <CountUpNumber
                      end={files.length}
                      label="Files"
                    />
                  </Grid>
                  <Grid item>
                    <CountUpNumber
                      end={rows.length}
                      label="Columns"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ColumnChart rows={rows} />
          </Grid>
          <Grid item xs={12}>
            <ExploreTable rows={rows} />
          </Grid>
      </Grid>
    </Container>
  )
}