import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import { useMediaQuery, Button } from '@material-ui/core';
import SectionHeader from 'components/molecules/SectionHeader';

//redux
import { useDispatch } from 'react-redux'
import { showAuthModal } from 'redux/General/actions'

const useStyles = makeStyles(theme => ({
  root: {},
  galleryMedia: {
    width: 60,
    height: 60,
    marginLeft: theme.spacing(-2),
    border: '3px solid white',
    '&:first-child': {
      marginLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
      width: 100,
      height: 100,
    },
    [theme.breakpoints.up('md')]: {
      width: 140,
      height: 140,
    },
  },
}));

const GetStarted = props => {
  const { data, className, ...rest } = props;
  const classes = useStyles();
  const dispatch = useDispatch()

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <SectionHeader
        title="Join our community"
        subtitle="Join our growing community to make your business dreams come true."
        ctaGroup={[
          <Button
            variant="contained"
            size={isMd ? 'large' : 'medium'}
            color="primary"
            onClick={e => dispatch(showAuthModal('login'))}
          >
            Get Started
          </Button>,
        ]}
      />
    </div>
  );
};

GetStarted.propTypes = {
  /**
   * External classes
   */
  className: PropTypes.string,
  /**
   * data to be rendered
   */
  data: PropTypes.array.isRequired,
};

export default GetStarted;
