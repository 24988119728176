import React from 'react'
import { DialogContent, Typography, DialogActions, Button } from '@material-ui/core'
//redux
import { useDispatch, useSelector } from 'react-redux'
import { hideError } from 'redux/General/actions'

function GeneralError() {
  const { errorMessage } = useSelector(store=>store.general)
  const dispatch = useDispatch()
  return (
    <div>
      <DialogContent style={{maxWidth: '300px'}}>
        <Typography gutterBottom>{ errorMessage && errorMessage}</Typography>
      </DialogContent>
      <DialogActions >
          <Button fullWidth onClick={e => dispatch(hideError())} variant='contained' color='primary'>Okay</Button>
      </DialogActions>
    </div>
  )
}

export default GeneralError
