import React, { useEffect } from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import Paper from "@material-ui/core/Paper"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import Upload from "./1-Upload/Upload"
import Preprocess from "./2-Preprocess/Preprocess"
import Train from "./3-Train/Train"
import Predict from './4-Predict/Predict'
import Deploy from './5-Deploy/Deploy'
import ColumnInfo from './2a-ColumnInfo/ColumnInfo'
import Header from 'components/organisms/Header/Header'
import ActionBar from './components/ActionBar'
import { CircularProgress, Divider, Link, Grid } from "@material-ui/core"
import { useProjectData, useCheckpointData, useDataModel, useDataFiles } from 'utils/hooks'
import { Route, useHistory, useRouteMatch } from "react-router-dom"
import { useSelector } from "react-redux"


const useStyles = makeStyles(theme => ({
  centered: {
    textAlign: 'center',
    width: '100%'
  },
  appBar: {
    position: "relative",
  },
  layout: {
    paddingTop: '20px',
    width: "auto",
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up(1100 + theme.spacing(2) * 2)]: {
      maxWidth: 1100,
      marginLeft: "auto",
      marginRight: "auto",
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    paddingTop: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.up(1100 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      //paddingBottom: theme.spacing(3),
      //paddingTop: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
    overflowX: 'auto',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer'
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  paddedBottom: {
    paddingBottom: '20px'
  },
  colorPrimary: {
    color: theme.palette.primary.main,
  }
}))

const steps = ["Upload", "Preprocess", "Train", "Predict", "Deploy", "Monitor"]

export default function Projectv2() {
  const classes = useStyles()
  const [activeStep, setActiveStep] = React.useState(0)
  const theme = useTheme()
  const match = useRouteMatch()
  const history = useHistory()

  useProjectData(match.params.projectId)
  useCheckpointData(match.params.projectId)
  useDataModel(match.params.projectId)
  useDataFiles(match.params.projectId)

  useEffect(() => {
    var step = steps.findIndex(item => item.toLowerCase() === match.params.page.toLowerCase())
    setActiveStep(step)
  }, [match.params.page])

  const projectData = useSelector(store => store.project)

  const changeStep = (index) => {
    history.push(match.url.split('/').slice(0,-1).join('/') + '/' + steps[index])
  }

  console.log('step', activeStep, steps.length -1, activeStep === steps.length -1)
  return (
    <div id='newproject'>
      <Header headerScroll={{height: 25, color: theme.palette.background.default}} />
      <div className={classes.layout}>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.paddedBottom}><Link href='#' onClick={e => history.push('/user')}>Back to dashboard</Link></div>
          <Typography color='textPrimary' gutterBottom variant="h5">{projectData.title}</Typography>
          <Grid container spacing={2}>
            <Grid item >
              <Typography variant='subtitle1' color='textSecondary'><span className={classes.colorPrimary}>ID: </span> {projectData.projectId}</Typography>
            </Grid>
            <Grid item>
              <Typography color='textSecondary' variant='subtitle1' className={classes.paddedBottom}><span className={classes.colorPrimary}>Type: </span>{projectData.type}</Typography>
            </Grid>
            <Grid item>
              <ActionBar />
            </Grid>
          </Grid>
          <Divider />
          <Stepper nonLinear activeStep={activeStep} className={classes.stepper}>
            {steps.map((label, index) => (
              <Step key={label} onClick={e => changeStep(index)}>
                  <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <div className={classes.centered}>
                <CircularProgress size={50} />
              </div>
            ) : (
              <React.Fragment>
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/upload`} component={() => <Upload match={match} />} />
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/preprocess`} component={() => <Preprocess />} />
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/train`} component={() => <Train />} />
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/predict`} component={() => <Predict />} />
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/deploy`} component={() => <Deploy />} />
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/explore/column/:column`} component={() => <ColumnInfo />} />
              <Route exact path={`/projectv2/${match.params.projectId}/type/dc/pg/monitor`} component={() => <Typography color='primary'>Coming soon...</Typography>} />
                {/*getStepContent(activeStep)*/}
                <div className={classes.buttons}>
                  {activeStep !== 0 && (
                      <Button onClick={e => changeStep(activeStep - 1)} className={classes.button} disabled={activeStep === 0}>
                        Back
                      </Button>
                  )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={e => changeStep(activeStep + 1)}
                      className={classes.button}
                      disabled={activeStep === steps.length -1}
                    >
                      Next
                    </Button>
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </div>
    </div>
  )
}