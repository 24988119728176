import { AuthActions } from 'redux/Auth/actions';

const initialState = {
  authUser: null,
  authUID: null,
  email: null,
  isVerifying: false,
  error: null,
  authenticated: false
};

export function authReducer(state = initialState, action) {
  switch (action.type) {

    case AuthActions.AUTH_USER:
      return {
        ...state,
        ...action.data,
        authenticated: true
      };

    case AuthActions.SIGN_OUT_USER:
      return initialState;

    case AuthActions.IS_VERIFYING:
      return {
        ...state,
        isVerifying: action.data
      };
    
    case AuthActions.AUTH_ERROR:
      return{
        ...state,
        error: action.payload,
        authUser: null,
        authUID: null,
        email: null,
        authenticated: false
      }

    default:
      return state;
  }
}
