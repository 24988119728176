import React, { useEffect } from 'react'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { setError } from 'redux/General/actions'
//material ui
import { Container } from '@material-ui/core'
//components
import TrainingForm from './Sections/TrainingForm'
import TrainingStats from './Sections/TrainingStats'
import ModelAccuracy from './Sections/ModelAccuracy'
import InterpretModel from './Sections/InterpretModel'

function Train(props) {
  const dispatch = useDispatch()
  const store = useSelector(store => store)
  const mostRecentData = useSelector(store => store.project.mostRecentData)
  const trainingError = useSelector(Store => store.project.trainingError)

  useEffect(() => {
    if (trainingError) {
      dispatch(setError('TRAINING', 'Last Training Job Failed!'))
    }
  }, [dispatch, trainingError])

  return (
    <Container maxWidth='lg' data-aos="fade-up">
      {!store.project.isTraining?(<TrainingForm />):(<TrainingStats />)}
      <ModelAccuracy />
      {!store.project.isTraining && mostRecentData?(<InterpretModel />):(null)}
    </Container>
  )
}

export default Train
