import { useTheme } from '@material-ui/core';
import React, { useRef } from 'react'
var CanvasJSReact = require('utils/canvasjs.react').default;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function Histogram (props) {
  const {categories, columnName} = props
  const theme = useTheme()
  const chart = useRef()

  const data = categories.map((item, i) => ({
    label: item.label,
    y: item.y
  }))
  console.log(data)
  const options = {
    theme: "light1", // "light1", "dark1", "dark2"
    animationEnabled: true,
    zoomEnabled: true,
    backgroundColor: theme.palette.background.card,
    axisX: {
      tickColor: 'white',
      lineColor: 'white',
      gridDashType: "dot",
      gridThickness: 0,
      includeZero: 'false',
      labelFontColor: 'white',
      valueFormatString: "#,##0.##",
      interval: 1,
      labelAutoFit: true,
      labelAngle: -90,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        color: 'white'
      }
    },
    axisY: {
      minimum: 0,
      //maximum: 101,
      title: '# of rows',
      titleFontColor: "white",
      valueFormatString: "#,##0.##",
      tickColor: 'white',
      lineColor: 'white',
      labelFontColor: 'white',
      includeZero: false,
      gridThickness: 0,
      //logarithmic: true,
      titleFontSize: 16,
      crosshair: {
        enabled: true,
        snapToDataPoint: true,
        color: 'white'
      }
    },

    legend: {
      fontColor: 'white'
    },
    data: [
      {
        type: "column",
        name: columnName,
        color: "#21CE99",
        lineColor: "#21CE99",
        showInLegend: true,
        dataPoints: data
      },
    ]
  }

  const containerProps =  {
    height: '450px'
  }

  return (
    <div>
      <CanvasJSChart containerProps={containerProps} options={options} ref={chart} />
    </div>
  )
}

export default Histogram