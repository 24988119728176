import React from 'react'
//material
import { Button, makeStyles, CircularProgress } from '@material-ui/core'
import Person from '@material-ui/icons/Person'
import PersonAdd from '@material-ui/icons/PersonAdd'
import Home from '@material-ui/icons/Home'
//redux
import { useSelector, useDispatch } from 'react-redux'
import { showAuthModal } from 'redux/General/actions'
//router
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
  container: {
    color: 'white'
  },
  loader: {
    padding: '15px'
  }
}));

export default function HeroLinks () {
  const classes = useStyles()
  const isVerifying = useSelector(store => store.auth.isVerifying)
  const authenticated = useSelector(store => store.auth.authenticated)
  const dispatch = useDispatch()
  const history = useHistory()

  if (!isVerifying && !authenticated) {
    return (
      <div className={classes.container}>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.margin}
          startIcon={<PersonAdd />}
          onClick={e => dispatch(showAuthModal('register'))}
        >Try Free
        </Button>

        <Button
          variant="text"
          color="primary"
          size="medium"
          className={classes.margin}
          startIcon={<Person />}
          onClick={e => dispatch(showAuthModal('login'))}
        >Login
        </Button>
      </div>
    )
  } else if (authenticated) {
    return (
      <React.Fragment>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        className={classes.margin}
        startIcon={<Home />}
        onClick={e => history.push('/user')}
      >
      My Account
      </Button>

      </React.Fragment>
    )

  } else {
    return (
      <div className={classes.loader}>
      <CircularProgress size={35} />
      </div>
    )
  }

}
